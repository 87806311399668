import styled, { keyframes } from 'styled-components';

// Define keyframes for the gradient animation
const gradientAnimation = keyframes`
linear-gradient(to right, #FFC611, #12d8fa);
   
`;
const gradientAnimation1 = keyframes`
    0% {
        background: linear-gradient(to right, #ff4e50, #f9d423);
    }
    50% {
        background: linear-gradient(to right, #FFC611, #12d8fa);
    }
    100% {
        background: linear-gradient(to right, #ff4e50, #f9d423);
    }
`;

// Styled component for the banner container
export const BannerContainer = styled.div`
position: relative;
    padding: 20px;
    // border-radius: 10px;
    overflow: hidden;
     background:${props => props.color === "one" && 'linear-gradient(to right, #FFC611, #12d8fa)' || props.color === "two" && 'linear-gradient(to right, #FFC611, #12d8fa)' || props.color === "three" && 'linear-gradient(to right, #ff4e50, #f9d423)' };
     transition: background 1s ease;
`;

// Styled component for the info content (paragraph)
export const InfoContent = styled.p`
  color: white; 
    font-size: 18px;
    text-align: center;
    padding:0;
`;