import React from "react";
import "./hospitality.css";
import OverlayCard from "../../components/OverlayCard";
import NewFooter from "../../components/NewFooter/NewFooter";
import { Carousel } from "../../components/VerticalCarousel";
import vivre from "../../assets/solutions/Vivre.png";
import sia from "../../assets/solutions/sia.png";
import b1 from "../../assets/hospitality/omini.png"
import b2 from "../../assets/hospitality/exp.png"
import b3 from "../../assets/hospitality/revenue.png"
import hos from "../../assets/hospitality/image 46.png"


export default function Hospitality() {
  const whitepapers = [
    {
      id: 1,
      src: hos,
      alt: "image1 for carousel",
    },
    // {
    //   id: 2,
    //   src: vivre,
    //   alt: "image 2 for carousel",
    // },
  ];
 
  const blogs = [
    { title: 'Omni Channels', description: 'We have enabled many large operators with Omni channel experience for hotel guests.', name: '', image: b1 },
    { title: 'Experience', description: 'Bring unparallel user experience through unification of OTT, Gaming, E-Commerce and other service offerings.', name: '', image: b2 },
    { title: 'Revenue', description: 'Increase your offerings / revenues from your Guests by upto 2x.', name: '', image: b3 },
  
  ];
  return (
    <div className="vivre-main">
      <div className="vivre-container">
        <div className="vivre-logo">
          <img src={require("../../assets/hospitality/logo1.png")} alt="" />
          <h3>Hospitality</h3>
        </div>
        <div className="vivre-des">
          <p>
            We provide top tier solutions for the Hospitality Industry by leveraging our OTT experience.
          </p>
        </div>
        <OverlayCard blogs={blogs} />
      </div>
      <div className="">
        {/* <img src={require("../../assets/vivre/SARA Insights.png")} /> */}
        <div className="hospitality-carousel">
          <Carousel data={whitepapers} />
          <div className="hospitality-des">
            <div className="hospitality-title">
              <h3>Movies and LIVE </h3>
              <h3>TV - Kaltura</h3>
              <p>• Empower your guests to watch Video on Demand through rich and interactive OTT Experience on Guest’s TV.</p>
              <p>• Increase your revenue and value add by offering LIVE TV and VOD as On-Demand or Pay per view.</p>
              <p>• Monetize through AVOD offerings.</p>
              <p>• Sell Local Content</p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="w-m-s-p">
          <div className="sara-title2">
            <h3>What makes our Hospitality Services Special</h3>
          </div>
          <div className="features">
            <img src={require("../../assets/hospitality/Frame1.png")} alt="" />
            <img src={require("../../assets/hospitality/Frame2.png")} alt="" />
            <img src={require("../../assets/hospitality/Frame3.png")} alt="" />
            <img src={require("../../assets/hospitality/Frame4.png")} alt="" />
            <img src={require("../../assets/hospitality/Frame5.png")} alt="" />
            <img src={require("../../assets/hospitality/Frame6.png")} alt="" />
          </div>
        </div>
      </div>
      <NewFooter />
    </div>
  );
}

