import React from 'react'
import { Slide, ContentContainer, HeroWrapper, FlexContainer, BlueLine, HeroCaption, HeroTitle, Button } from "./about.style"
import { Typography } from '@mui/material';
import Career from './Career';
import WhoWeAre from './WhoWeAre';
import NewFooter from '../../components/NewFooter/NewFooter';

const AboutUs = () => (
  <div>
    <section>
      <WhoWeAre/>
    </section>
    {/* <section>
      <Career/>
    </section> */}
    <section>
    <NewFooter/>
    </section>
  </div>
);
export default AboutUs

