import React from "react";
import FrontendApp from "./OttFrontend";
import Top from "../components/Top";
import Footer from "../components/Footer";

const OttAppPage = () => {
   return (
      <div >
         <Top />
         <FrontendApp />
         <div className="" style={{ display: "flex", flexFlow: "column", minHeight: "500px", gap: "7rem", paddingTop: "5rem", paddingLeft: "5rem", maxWidth: "70%", maxWidth: "70%", padding: "99px" }}>
            <h1>
               <span>Why {" "}</span>
               <span style={{ color: "red" }}>develop  </span>
               <span>OTT {" "}</span>
               <span>apps?</span>
            </h1>
            <div >
               <div style={{ marginBottom: "10px", fontWeight: " 600", fontSize: "1.3125rem" }}>Intrested in OTT?</div>
               <div className="elementor-element elementor-element-dcab1dc elementor-widget__width-initial  elementor-widget elementor-widget-button" style={{ marginTop: "30px" }}>
                  <div className="elementor-widget-container">
                     <div className="elementor-button-wrapper">
                        <a className="elementor-button elementor-button-link elementor-size-sm" href="mailto:ottsales@horizonind.org?subject=Book%20A%20Demo">
                           <span className="elementor-button-content-wrapper">
                              <span className="elementor-button-text">Book Your FREE Demo Today</span>
                           </span>
                        </a>
                     </div>
                  </div>
               </div>
               <div style={{
                  paddingTop: "2rem",
                  fontStyle: "normal",
                  fontWeight: "300",
                  fontSize: "21px",
                  lineHeight: "35px",
                  maxWidth: "100%",
                  // wordBreak:"break-word"
               }} >
                  <p>Horizon develops OTT applications across all major popular platforms. From consultation and strategy, to development, quality assurance testing, vendor certification and Digital Rights Management, our comprehensive OTT application services will help you on your journey to reach more customers. If you are looking to expand your audience and serve you content across a wider range of OTT devices and platforms, our team will work with you to create an OTT app tailored to what you need.</p>
               </div>
            </div>

         </div>
         <div style={{ background: "blue" }}>
            <Footer color={"#2D1347"} />
         </div>


      </div>
   )
}
export default OttAppPage