import styled from 'styled-components';

export const ContainerCard = styled.div`
    margin: 10px;
    perspective: 700px;
    display: flex;
    flex-wrap: wrap;
    gap: 17px;
    justify-content: center;
    padding:30px
    
`;

export const CardBox = styled.div`
    width: 300px;
    height: 400px;
    transition: all 0.8s ease;
    transform-style: preserve-3d;
    cursor: pointer;
    margin: 5px;
    position: relative;

    &:hover {
        transform: rotateY(180deg);
    }

    .back {
        padding:10px;
        transform: rotateY(180deg);
        background: white;
        // font-size:18px;
        display: flex;
        flex-flow: column;
        backface-visibility: hidden;
    }

    .front {
        background: url(${props => props.img});
        // font-size: 35px;
        // font-weight: 600;
        // background-size: cover;
        // color: white;
        // backface-visibility: hidden; /* Ensure backface visibility is hidden */
   background-size: cover;
    background-repeat: no-repeat;
    max-width: 100%;
    background-position: center;
    flex-direction: column;
 
        }

    .front, .back {
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 2px 5px gray;
        // background:#96eaff;
    }
   .description {
    margin-top: 15px;

    ul {
      padding-left: 20px;  
      list-style-type: none; 

      li {
        position: relative;
        margin-bottom: 10px; 
        padding-left: 12px; 

        &:before {
          content: ""; 
          color: #3f96ef; 
          font-size: 3em; 
          position: absolute;
          left: -20px; 
          top: 0;
          transform: translateY(50%);
        }

      
        & > p {
          margin: 0; 
        //   padding-left: 20px; 
        //   text-indent: -20px; 
        }
      }
    }
  } 
`;
