import React from 'react';
import styled from 'styled-components';

const PageContainer = styled.div`
  background-color: #f8f9fa; 
  padding: 50px 20px;
  display: flex;
  justify-content: center;
`;

const ContentWrapper = styled.div`
  max-width: 900px;
  background-color: #ffffff;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  line-height: 1.8;
  color: #333; 
`;

const Title = styled.h1`
  font-size: 2.5em;
  color: #002b5c; 
  text-align: center;
  margin-bottom: 40px;
  font-weight: bold;
`;

const SectionTitle = styled.h2`
  font-size: 1.75em;
  color: #003366; 
  margin-bottom: 20px;
  font-weight: 600;
`;

const Paragraph = styled.p`
  margin-bottom: 20px;
  font-size: 1.1em;
  color: #555; 
`;

const List = styled.ul`
  margin-left: 20px;
  margin-bottom: 20px;
`;

const ListItem = styled.li`
  margin-bottom: 10px;
  font-size: 1.1em;
  color: #555;
  list-style: none; 
  
`;


const ContactInfo = styled.div`
  margin-top: 40px;
  padding: 20px;
  background-color: #eef2f7;
  border-left: 5px solid #003366;
  border-radius: 5px;
  font-size: 1.1em;
`;

const PrivacyPolicy = () => {
  return (
    <PageContainer>
      <ContentWrapper>
        <Title>Data Protection Policy and Privacy Policy</Title>
        <Paragraph><strong>Effective date:</strong> 3 Sep 2024</Paragraph>
        
        <SectionTitle> Introduction</SectionTitle>
        <Paragraph>
          This Data Protection Policy and Privacy Policy ("Policy") outlines how we, Horizon Broadband Private Limited, 
          collect, use, disclose, and protect personal information. We are committed to ensuring the privacy and 
          security of your personal data in compliance with the relevant data protection laws and regulations in both countries.
        </Paragraph>

        <SectionTitle> Definitions</SectionTitle>
        <List>
          <ListItem><strong>Personal Information</strong> refers to any information that can directly or indirectly identify an individual, such as name, address, email, phone number, etc.</ListItem>
          <ListItem><strong>Horizon</strong> refers to our organization.</ListItem>
          <ListItem><strong>Data Controller</strong> means the entity responsible for determining the purposes and means of processing personal information.</ListItem>
          <ListItem><strong>Data Processor</strong> means any party that processes personal information on behalf of the Data Controller.</ListItem>
        </List>

        <SectionTitle> Data Collection and Use</SectionTitle>
        <List>
          <ListItem><strong> Consent:</strong> We will obtain explicit consent from individuals before collecting their personal information. By using our services, you consent to the collection, storage, and use of your personal information as outlined in this Policy.</ListItem>
          <ListItem><strong> Purpose Limitation:</strong> We will collect and process personal information only for specific, explicit, and legitimate purposes. Personal information will not be further processed in a manner incompatible with these purposes.</ListItem>
          <ListItem><strong> Data Minimization:</strong> We will only collect personal information that is relevant, adequate, and limited to what is necessary for the intended purposes.</ListItem>
          <ListItem><strong> Information Security:</strong> We implement reasonable technical and organizational measures to protect personal information from unauthorized access, disclosure, alteration, and destruction.</ListItem>
        </List>

        <SectionTitle> Data Disclosure and Sharing</SectionTitle>
        <List>
          <ListItem><strong> Third-party Service Providers:</strong> We may share personal information with trusted third-party service providers who assist us in delivering our services. These providers are required to maintain the confidentiality and security of the information and are prohibited from using it for any other purpose.</ListItem>
          <ListItem><strong> Legal Obligations:</strong> We may disclose personal information if required by law or in response to valid legal requests from competent authorities.</ListItem>
        </List>

        <SectionTitle> Data Subject Rights</SectionTitle>
        <List>
          <ListItem><strong> Access:</strong> Upon request, individuals have the right to access their personal information held by us. We will respond to such requests in accordance with applicable laws.</ListItem>
          <ListItem><strong> Correction and Deletion:</strong> If personal information is inaccurate or incomplete, individuals may request its correction. Additionally, individuals have the right to request the deletion of their personal information under certain circumstances.</ListItem>
        </List>

        <SectionTitle> International Data Transfers</SectionTitle>
        <Paragraph><strong> Cross-Border Transfers:</strong> In case we transfer personal information, we will ensure adequate data protection measures are in place, such as Standard Contractual Clauses or other approved mechanisms.</Paragraph>

        <SectionTitle> Retention</SectionTitle>
        <Paragraph>We will retain personal information for as long as necessary to fulfill the purposes for which it was collected, or as required by law.</Paragraph>

        <SectionTitle> Amendments</SectionTitle>
        <Paragraph>We may update this Policy from time to time to reflect changes in our practices. The updated Policy will be made available on our website.</Paragraph>

        <SectionTitle> Contact Information</SectionTitle>
        <ContactInfo>
          If you have any questions, concerns, or requests related to this Policy, please contact us at <a href="mailto:ottsales@horizonind.org">ottsales@horizonind.org</a>.
        </ContactInfo>

        <Paragraph>By using our services, you acknowledge that you have read and understood this Data Protection Policy and Privacy Policy and agree to the collection, use, and disclosure of your personal information as described herein.</Paragraph>
      </ContentWrapper>
    </PageContainer>
  );
};

export default PrivacyPolicy;
