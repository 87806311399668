import React, { useEffect, useState } from 'react';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Logo from '../assets/Horizon_logo_192x192.png';

const FEATURES = [
  {
    id: 'feature-1',
    Image_URL: 'path/to/Product_Engeener_Icon.png',
    Title: 'SARA',
    Disc: 'Technology. Products. Process',
    link: '/product/sara'
  },
  {
    id: 'feature-2',
    Image_URL: 'path/to/OTT_Products_Icon.png',
    Title: 'VIVRE',
    Disc: 'Experience. Excite. Engage',
    link: "/product/vivre"
  },
  {
    id: 'feature-3',
    Image_URL: 'path/to/Credit_Card_Icon.png',
    Title: 'Blinks',
    Disc: 'End to End for OTT/Media',
    link: "/product/blink"
  },
  {
    id: 'feature-4',
    Image_URL: 'path/to/Credit_Card_Icon.png',
    Title: 'Hospitality',
    Disc: 'End to End for OTT/Media',
    link: "/product/hospitality"
  },
];

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 30px 9px 30px;
  position: fixed;
  width: 100%;
  z-index: 25;
  background-color: white;
`;

const Navbar = styled.nav`
  display: flex;
  gap: 20px;
  align-items: center;
`;

const LogoContainer = styled.div`
  position: absolute;
  left: 30px;
`;

const StyledNavLink = styled(NavLink)`
  font-size: 18px;
  font-weight: 600;
  border-bottom: 3px solid transparent;
  padding-bottom: 4px;
  text-decoration: none;
  display: flex;
  align-items: center; /* Align the text vertically */

  &.active {
    color: #6054E4;
    border-bottom-color: #6054E4;
  }

  &:hover {
    color: #6054E4;
  }
`;

const DropdownMenu = styled.div`
  position: relative;
  display: inline-block;
`;

const DropdownContent = styled.div`
  display: ${({ open }) => (open ? 'block' : 'none')};
  position: absolute;
  background-color: white;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  min-width: 160px;
  z-index: 1;

  a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    font-size: 16px;

    &:hover {
      background-color: #f1f1f1;
    }
  }
`;

const Top = () => {
  const [scrolled, setScrolled] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const handleScroll = () => {
    const offset = window.scrollY;
    setScrolled(offset > 50);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleDropdownClick = (link) => {
    setDropdownOpen(false);
    navigate(link);
  };

  return (
    <Container scrolled={scrolled}>
      <LogoContainer>
        <Link to="/">
          <img width={60} height={60} src={Logo} alt="logo" />
        </Link>
      </LogoContainer>
      <Navbar>
        <StyledNavLink exact to="/" activeClassName="active">
          Home
        </StyledNavLink>
        <StyledNavLink to="/solutions" activeClassName="active">
          Solutions
        </StyledNavLink>

        <DropdownMenu
          onMouseEnter={() => setDropdownOpen(true)}
          onMouseLeave={() => setDropdownOpen(false)}
        >
          <StyledNavLink to="/products" activeClassName="active">
            Products
          </StyledNavLink>
          <DropdownContent open={dropdownOpen}>
            {FEATURES.map((feature) => (
              <Link
                key={feature.id}
                to={feature.link}
                onClick={() => handleDropdownClick(feature.link)}
              >
                {feature.Title}
              </Link>
            ))}
          </DropdownContent>
        </DropdownMenu>

        <StyledNavLink to="/customers" activeClassName="active">
          Customers
        </StyledNavLink>
        <StyledNavLink to="/resources" activeClassName="active">
          Resources
        </StyledNavLink>
        <StyledNavLink to="/about" activeClassName="active">
          About Us
        </StyledNavLink>
      </Navbar>
    </Container>
  );
};

export default Top;
