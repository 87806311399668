import React, { useState } from 'react';
import styled from 'styled-components';
import b1 from "../../assets/blogs/b1.png"
import b2 from "../../assets/blogs/b2.png"
import b3 from "../../assets/blogs/b3.png"
import b4 from "../../assets/blogs/b4.png"
import b5 from "../../assets/blogs/b5.png"
import { useNavigate } from 'react-router';



const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
`;

const Header = styled.header`
  text-align: center;
  margin: 20px 0;
`;


const CardGrid = styled.div`
    display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 columns */
  gap: 20px; /* Space between cards */
  @media (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr); /* 2 columns on medium screens */
  }
  @media (max-width: 800px) {
    grid-template-columns: 1fr; /* 1 column on small screens */
  }
`;

const StyledCard = styled.div`
  width: 390px;
  height: 260px;
  position: relative;
  overflow: visible; /* Allow the image to overflow */
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top:8rem;
  cursor: pointer;
`;

const OverlayImage = styled.img`
  width: 450px;
  height: 280px;
  position: absolute;
  top: -140px; /* Half of the image height to center it above the card */
  left: 50%;
  transform: translateX(-50%);
  border-radius: 8px 8px 0 0;
`;

const CardContent = styled.div`
  padding: 20px;
  margin-top:6rem;
`;

const Title = styled.h5`
  margin: 0;
  font-size: 18px;
  color:#6054E4
`;

const Description = styled.p`
color: #555;
  font-size: 12px;
  display: -webkit-box; /* Needed for webkit */
  -webkit-box-orient: vertical; /* Set the box's orientation */
  -webkit-line-clamp: 3; /* Number of lines to display before truncating */
  overflow: hidden; /* Hide the overflowing content */
  text-overflow: ellipsis; /* Add ellipsis at the end */
  white-space: normal; /* Ensure text wraps within the element */

`;

const Author = styled.p`
  color: #777;
`;

const ShowMoreButton = styled.button`
  display: block;
  margin: 20px auto;
  padding: 10px 20px;
  font-size: 1rem;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #0056b3;
  }
`;


const blogs = [
    // Replace this with your actual blog data
    {id:'gemification' , title: 'Gamification - AI Perspective', description: 'Over-the-Top (OTT) streaming industry has experienced significant growth, driven by the increasing demand for on-demand content and the growth of internet-connected devices. As OTT platforms continue to evolve, they face the challenge of keeping users engaged and loyal despite strong competition. One innovative approach gaining popularity is gamification, which involves applying game-design elements and principles in non-gaming contexts', name: 'Pujitha ', image: b1 },
    {id:'content-discovery', title: 'Content Discovery Through Search', description: "In today's digital landscape, where content discovery is expected to be 1-Click away, an ability to deliver personalized content is more critical than ever. Content search-driven recommendation can create quite an impact blending the precision of search with the personalization of recommendation engines", name: 'Abhilash', image: b2 },
    {id:'content-discovery-i', title: 'Content Discovery through the Lens of Personalization', description: "In today’s saturated OTT (Over-The-Top) market, where every streaming service provider is vying for user retention, personalization has become essential for stand out offering. But what exactly do users expect when it comes to personalized offerings on these platforms? Understanding this can be the difference between a fleeting visit and a long-term subscriber.", name: 'Abhilash', image: b3 },
    {id:'content-discovery-j', title: 'Enhancing Multiscreen Quality - Embracing Automation', description: 'In the dynamic world of Over-The-Top (OTT) services, ensuring a seamless and high-quality user experience across various platforms is paramount. We recognize the critical role of automation in achieving this goal', name: '', image: b4 },
];




export default function Blogs() {
    const [visibleBlogs, setVisibleBlogs] = useState(4);
    const navigate = useNavigate();

    const showMoreBlogs = () => {
        setVisibleBlogs((prev) => prev + 3);
    };
    const handleNavigateBlog = (blog,index)=>{
      console.log(blog,index,"blog,index");
      if (index==0) {
        // navigate('/gamification')
        window.open('/gamification', '_blank');
      }else if (index==1) {
        window.open('/contentDiscoverySearch', '_blank');
      }else if (index==2) {
        window.open('/BlogPagePersonalization', '_blank');
      }else if (index==3) {
        window.open('/BlogPageAutomation', '_blank');
      }
    }
    const BlogCard = ({ title, description, name, image ,onClick}) => (
        <StyledCard  onClick={onClick}>
            <OverlayImage src={image} alt={title} />
            <CardContent>
                <Title>{title}</Title>
                <Description>{description}</Description>
                {/* <Author>Author : {name}</Author> */}
            </CardContent>
        </StyledCard>
    );
    return (
        <Container>
            <div className='recent-blog'>
                <h3>Featured</h3>
            </div>
            {/* <img src={require("../../assets/blogs/Featured article.png")} alt='img' style={{ padding: "1rem" }} /> */}
            {/* <div className='recent-blog'>
                <h3>Recents</h3>

            </div> */}
            <CardGrid>
                {blogs.slice(0, visibleBlogs).map((blog, index) => (
                    <BlogCard key={index} {...blog} onClick={() => handleNavigateBlog(blog, index)} />
                ))}
            </CardGrid>
            {visibleBlogs < blogs.length && (
                <ShowMoreButton onClick={showMoreBlogs}>
                    Show More
                </ShowMoreButton>
            )}
        </Container>
    );

}
