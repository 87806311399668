import React, { useState } from "react";
import "./VerticalCarousel.css";
import leftArrow from "../../assets/carouselIcon/Left.png";
import rightArrow from "../../assets/carouselIcon/Right.png";
import { useNavigate } from "react-router-dom";

export const Carousel = ({ data }) => {
    const [slide, setSlide] = useState(0);
    const navigate = useNavigate();

    const nextSlide = () => {
        setSlide((prevSlide) => (prevSlide === data.length - 1 ? 0 : prevSlide + 1));
    };

    const prevSlide = () => {
        setSlide((prevSlide) => (prevSlide === 0 ? data.length - 1 : prevSlide - 1));
    };

    return (
        <div className="carousele-vert z-10">

            <div className="gradient-overlay-vert"></div>
            <div className="side-pagination-vert">
                <img src={leftArrow}
                    alt="Previous Slide" onClick={prevSlide}
                    className={`w-[30px] h-[30px] z-10`} />
                <div className="indicate-vert z-20">
                    {data.map((_, idx) => (
                        <button
                            key={idx}
                            className={
                                slide === idx ? "indicator-vert active" : "indicator-vert indicator-vert-inactive"
                            }
                            onClick={() => setSlide(idx)}
                        ></button>
                    ))}
                </div>
                <img src={rightArrow}
                    alt="Next Slide" onClick={nextSlide}
                    className={`w-[30px] h-[30px] z-10`} />

            </div>


            <div className="slides-container-vert">
                {data.map((item, idx) => (
                    <img
                        src={item.src}
                        alt={item.alt}
                        key={idx}
                        className={slide === idx ? "slide active z-10 object-cover" : "slide slide-hidden"}
                        onClick={() => navigate(item.href)}
                    />
                ))}
            </div>

        </div>
    );
};
