import React from 'react';
import styled from 'styled-components';

// Styled components
const Section = styled.section`
  -webkit-text-size-adjust: 100%;
  font: 112.5%/1.45em Raleway, georgia, serif;
  --rem: 16;
  font-family: Raleway, georgia, serif;
  font-weight: 400;
  word-wrap: break-word;
  font-kerning: normal;
  font-feature-settings: "kern", "liga", "clig", "calt";
  cursor: default;
  box-sizing: inherit;
  min-height: calc(100vh - 60px);
  color: #fff;
  background-image: url('https://cdn.prod.website-files.com/63c597ccd1518e710eed86ef/6420cb1b927bd530252baf8a_home-life.webp');
//   background-image: url('https://www.ifeelsmart.com/static/joinUs-ef5916781d2a77be29290bc539e4e719.jpg');
  background-size: cover;
  background-position: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
`;

const Heading = styled.h2`
  margin: 0;
`;

const Paragraph = styled.p`
  margin: 0 0 1em 0;
`;

const JobButton = styled.a`
  color: #fff;
  background-color: #007bff;
  padding: 0.5em 1em;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }
`;

// React component
const Career = () => {
  return (
    <Section>
      <Heading>JOIN US!</Heading>
      <Paragraph>Apply jobs in our careers page.</Paragraph>
      <JobButton href="https://www.welcometothejungle.co/companies/ifeelsmart/jobs" target="_blank">
        View job
      </JobButton>
    </Section>
  );
};

export default Career;

