import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';

// Styled components for the carousel
const CarouselContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 150px; 
  overflow: hidden;
  position: relative;
  width: 100%;
  padding: 0 20px;
`;

const CarouselWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-x: auto;
  width: calc(100% - 40px); 
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth; 
  padding: 5px;
`;

const CarouselItem = styled.div`
  flex: 0 0 auto; 
  margin: 0 10px; 
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease, width 0.3s ease, height 0.3s ease;
  border-radius: 0.5rem;
  width: ${({ isActive }) => (isActive ? '140px' : '100px')};  // Larger size for active
  height: ${({ isActive }) => (isActive ? '90px' : '60px')};   // Larger size for active
  transform: ${({ isActive }) => (isActive ? 'scale(1.2)' : 'scale(1)')}; // Pop effect on active item
  cursor: pointer;
  scroll-snap-align: center;
  &:focus {
    outline: none;
  }
`;

const Logo = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  border-radius: 0.5rem;
`;

// Styled components for related content
const RelatedContentContainer = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const RelatedImage = styled.img`
  max-height: 50vh; 
  max-width: 90vw; 
  object-fit: contain;
  border-radius: 0.5rem;
`;

const Cara = ({ logos, relatedImages }) => {
  const [focusedIndex, setFocusedIndex] = useState(0);
  const logoRefs = useRef([]);

  useEffect(() => {
    if (logoRefs.current[focusedIndex]) {
      logoRefs.current[focusedIndex].focus();
    }
  }, [focusedIndex]);

  const handleLogoClick = (index) => {
    setFocusedIndex(index);
  };

  return (
    <>
      <CarouselContainer>
        <CarouselWrapper>
          {logos.map((logo, index) => (
            <CarouselItem
              key={index}
              isActive={index === focusedIndex}
              tabIndex={0}
              ref={(el) => (logoRefs.current[index] = el)}
              onClick={() => handleLogoClick(index)}
            >
              <Logo src={logo} alt={`Logo ${index + 1}`} />
            </CarouselItem>
          ))}
        </CarouselWrapper>
      </CarouselContainer>
      <RelatedContentContainer>
        {relatedImages[focusedIndex] && (
          <RelatedImage src={relatedImages[focusedIndex]} alt={`Related content for logo ${focusedIndex + 1}`} />
        )}
      </RelatedContentContainer>
    </>
  );
};

export default Cara;
