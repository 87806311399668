import React from "react";
import styled from "styled-components";

const Container = styled.div`
  max-width: 900px;
  margin: 40px auto;
  padding: 20px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  //   font-family: "Roboto", sans-serif;
`;

const Heading = styled.h1`
  text-align: center;
  color: #333;
  margin-bottom: 20px;
  font-size: 2.5rem;
  font-weight: 700;
`;

const SubHeading = styled.h2`
  color: #444;
  margin-top: 30px;
  margin-bottom: 15px;
  font-size: 1.75rem;
  font-weight: 600;
  border-bottom: 2px solid #ddd;
  padding-bottom: 5px;
`;

const Text = styled.p`
  color: #555;
  line-height: 1.7;
  margin-bottom: 20px;
  font-size: 1rem;
  text-align: justify;
`;

const Link = styled.a`
  color: #007bff;
  text-decoration: none;
  font-weight: 500;
  &:hover {
    text-decoration: underline;
  }
`;

const List = styled.ul`
  margin-left: 20px;
  list-style-type: disc;

  li::before {
    content: "";
    position: absolute;
    left: -16px;
    top: 50%;
    transform: translateY(-50%);
    width: 10px;
    height: 10px;
    background-color: rgb(5, 174, 240);
    border-radius: 50%;
  }
`;
const ListFirst = styled.ul`
  margin: 0;
  padding-left: 20px;
  line-height: 1.7;

  li::before {
    content: "";
    position: absolute;
    left: -16px;
    top: 25%;
    transform: translateY(-50%);
    width: 10px;
    height: 10px;
    background-color: rgb(5, 174, 240);
    border-radius: 50%;
  }
`;
const ListSecond = styled.ul`
  margin: 0;
  padding-left: 20px;
  line-height: 1.7;

  li::before {
    content: "";
    position: absolute;
    left: -16px;
    top: 50%;
    transform: translateY(-50%);
    width: 10px;
    height: 10px;
    background-color: rgb(5, 174, 240);
    border-radius: 50%;
  }
`;

const ListItem = styled.li`
  margin-bottom: 10px;
  position: relative;
  padding-left: 20px;
  margin-bottom: 10px;
  font-size: 1rem;
  color: #555;
`;

const TermsAndConditions = () => {
  return (
    <Container>
      <Heading>Terms and Conditions</Heading>

      <Text>
        This document is an electronic record of the Information Technology Act,
        2000 ("IT Act"), which mandates the publishing of rules and regulations,
        privacy policy, and terms of use for access or usage of the Website.
      </Text>

      <Text>
        Terms and Conditions read with the privacy policy, disclaimers, having
        domain name{" "}
        <Link href="https://horizonbroadband.co.in/" target="_blank">
          https://horizonbroadband.co.in/
        </Link>
        , shall govern your use / access of the website/products, its related
        content, sites and hyperlinks owned, maintained, and operated by Horizon
        Broadband Private Ltd.
      </Text>

      <Text>These Terms apply to all Users of the Website.</Text>

      <SubHeading>Acceptance of Terms</SubHeading>

      <Text>
        This document is an electronic record of the Information Technology Act,
        2000 ("IT Act"), which mandates the publishing of rules and regulations,
        privacy policy, and terms of use for access or usage of the Website.
      </Text>

      <Text>
        In these Website Terms of Use (referred to herein as the “Agreement” or
        “Terms”) “Horizon Broadband” refers to Horizon Broadband Private Limted,
        Gurgoan, India, and the terms “you” and “your” refer to you. If you are
        registering for a Horizon Broadband account to use the Service on behalf
        of an organization, then you are agreeing to these Terms for that
        organization and confirming to Horizon Broadband that you have the
        authority to bind that organization to these Terms (and, in which case,
        the terms “you” and “your” will refer to that organization).
      </Text>

      <Text>
        Please review these terms carefully. Once accepted, these terms become a
        binding legal commitment between you and Horizon Broadband. If you do
        not agree to be bound by these terms, you should not click the “I
        Accept” button and you should not use the service or access Horizon
        Broadband website.
      </Text>

      <Text>
        Your use of the Service and/or Website, your registration, and
        participation made available through the Website and/or Service, and/or
        your access to and use of content available through the Service and/or
        Website, is subject to these Terms and to the terms and conditions of
        Horizon Broadband Privacy Policy, which is published at{" "}
        <Link href="https://horizonbroadband.co.in/" target="_blank">
          https://horizonbroadband.co.in/
        </Link>
        , and which is incorporated herein by reference.
      </Text>

      <Text>
        The term “Service” as used in this agreement means any hosted service,
        technology, or application that Horizon Broadband makes available to
        you. By using the Service or visiting this website (referred to herein
        interchangeably as the “Horizon Broadband Website”, or “Website”), and
        by accessing content available through the Website or Service
        (including, without limitation, all content available through a partner
        website via an Application Programming Interface (“API”)), you signify
        your assent to these Terms. You may not use the Service or Website if
        you do not accept these Terms. Any new features or tools that are added
        to the Service or Website shall also be subject to these Terms.
      </Text>

      <SubHeading>Horizon Broadband Website</SubHeading>

      <Text>
        These Terms apply to all users of the Horizon Broadband Website,
        including users who contribute video content, information, and/or other
        materials, skills, or services via the website. The Website may contain
        links to third-party websites that are not owned or controlled by
        Horizon. By using the Website, you expressly release Horizon from any
        and all liability arising from your use of any third-party website.
        Accordingly, we encourage you to be aware when you leave the Horizon
        Broadband Website and to read the terms and conditions and privacy
        policy of each website that you visit.
      </Text>

      <SubHeading>Website and Service Access</SubHeading>

      <Text>
        Horizon Broadband hereby grants you permission to use the Website as set
        forth in these Terms, provided that:
      </Text>

      <ListFirst>
        <ListItem>
          You use the Website only for personal, internal, noncommercial
          purposes (please contact{" "}
          <Link href="mailto:ottsales@horizonind.org">
            ottsales@horizonind.org
          </Link>{" "}
          regarding the terms of a commercial license)
        </ListItem>
      </ListFirst>
      <ListSecond>
        <ListItem>You otherwise comply with these Terms</ListItem>
      </ListSecond>

      <Text>
        You agree not to use or launch any automated system, including without
        limitation, “robots,” “spiders,” “offline readers,” or similar tools,
        that accesses the Website, Service, or API in a manner that sends more
        request messages to the Horizon Broadband servers in a given period of
        time than a human can reasonably produce in the same period by using a
        conventional online web browser.
      </Text>

      <SubHeading>Intellectual Property Rights</SubHeading>

      <Text>
        The content on the Horizon Broadband Website, except all User Submitted
        Media (as defined herein), including without limitation, the text,
        software, scripts, graphics, photos, sounds, music, videos, and
        interactive features (together “Content”) and the trademarks, service
        marks, and logos contained therein (“Marks”), are owned by or licensed
        to Horizon Broadband, and are subject to copyright and other
        intellectual property rights under United States and foreign laws and
        international conventions.
      </Text>

      <Text>
        Content on the Website is provided to you ‘AS IS’ for your information
        and personal use only and may not be used, copied, reproduced,
        distributed, transmitted, broadcasted, displayed, sold, licensed, or
        otherwise exploited for any other purposes whatsoever without the prior
        written consent of the respective owners of the Content. Horizon
        Broadband reserves all rights not expressly granted in and to the
        Website and the Content.
      </Text>

      <Text>
        You agree not to engage in the use, copying, or distribution of any of
        the Content other than as expressly permitted herein, including any use,
        copying, or distribution of User Submitted Media of third parties
        obtained through the Website for any commercial purposes.
      </Text>

      <Text>
        If you download or print a copy of the Content for personal use, you
        must retain all copyright and other proprietary notices contained
        therein.
      </Text>

      <SubHeading>User Submitted Media</SubHeading>

      <Text>
        The Horizon Broadband Website may now or in the future permit the
        submission of videos, photos, and other forms of media, submitted by you
        and other users (“User Submitted Media”) and the hosting, sharing,
        importing, editing, remixing, and/or publishing of such User Submitted
        Media.
      </Text>

      <Text>
        You shall be solely responsible for your own User Submitted Media and
        the consequences of posting or publishing it. In connection with User
        Submitted Media, you affirm, represent, and/or warrant that: you have
        the written consent, release, and/or permission of each and every
        identifiable individual person in the User Submitted Media to use the
        name or likeness of each and every such identifiable individual person
        to enable inclusion and use of the User Submitted Media in the manner
        contemplated by the Website and these Terms.
      </Text>

      <SubHeading>Warranty Disclaimer</SubHeading>

      <Text>
        You agree that your use of the Horizon Broadband website and/or service
        shall be at your sole responsibility. To the fullest extent permitted by
        law, Horizon Broadband, its officers, directors, employees, and agents
        disclaim all warranties, express or implied, in connection with the
        website and/or service and your use thereof.
      </Text>

      <SubHeading>Indemnity</SubHeading>

      <Text>
        You agree to defend, indemnify and hold harmless Horizon Broadband, its
        subsidiaries, officers, directors, employees, and agents, from and
        against any and all claims, damages, obligations, losses, liabilities,
        costs or debt, and expenses (including but not limited to attorneys’
        fees) arising from or related to:
      </Text>

      <List>
        <ListItem>
          Your use of and access to the Website and/or Service
        </ListItem>
        <ListItem>Your violation of any term of these Terms</ListItem>
        <ListItem>
          Your violation of any third-party right, including without limitation
          any copyright, property, or privacy right
        </ListItem>
        <ListItem>
          Any claim that your User Submitted Media caused damage to a third
          party
        </ListItem>
      </List>

      <Text>
        This defense and indemnification obligation shall survive the
        termination and/or expiration of this Agreement.
      </Text>

      <Text style={{ textAlign: "center" }}>
        <strong>© 2024 Horizon Broadband. All rights reserved.</strong>
      </Text>
    </Container>
  );
};

export default TermsAndConditions;
