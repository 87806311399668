import React from "react";
import Carau from "./New";
import "./customers.css";
import astro from "../../assets/Customers/logo/Astrologo.png";
import dialog from "../../assets/Customers/logo/DialogTV.png";
import distv from "../../assets/Customers/logo/DishtvLogo.png";
import kaltura from "../../assets/Customers/kaltura.png";
import reshet from "../../assets/Customers/logo/DeshertLogos.png";
import astroPaper from "../../assets/Customers/Astropaper.png";
import Dialogpaper from "../../assets/Customers/Dialogpaper.png";
import Dishtvpaper from "../../assets/Customers/Dishtvpaper.png";
import Kalutrapaper from "../../assets/Customers/Kalutrapaper.png";
import Reshetpaper from "../../assets/Customers/Reshetpaper.png";
import SingleCarousel from "./SingleImageCarousel";
import samsungtv from "../../assets/Customers/Samung-tv-mockup.png";
import CarouselGallery from "./galleryCarousel";
import vivre from "../../assets/Customers/Vivre.png";
import gallery1 from "../../assets/Customers/gallery1.png";
import sooka from "../../assets/Customers/sooka.png";
import vivref from "../../assets/Customers/vivref.png";
import hospitality from "../../assets/Customers/Hospitality.png";
import hospitalityf from "../../assets/Customers/Hospitality Solutions Features- Image[2] 2.png";
import { Carousel } from "../../components/Carousel/Carousel1/Carousel";
import NewFooter from "../../components/NewFooter/NewFooter";
import flipkart from "../../assets/Customers/logo/FlipkartLogo.png";
import emeritus from "../../assets/Customers/logo/EmeritusLogo.png";
import banner1 from "../../assets/gallery/TVGallery1.png"
import banner2 from "../../assets/gallery/TVGallery2.png"
import banner3 from "../../assets/gallery/TVGallery3.png"
import banner4 from "../../assets/gallery/TVGallery4.png"
import banner5 from "../../assets/gallery/Mobilescreengallery1.png"
import banner6 from "../../assets/gallery/Mobile screen gallery 2.png"

export default function Customers() {
  const logos = [astro, reshet, distv, dialog,flipkart,
    emeritus];
  const relatedImages = [
    // Kalutrapaper,
    astroPaper,
    Reshetpaper,
    Dishtvpaper,
    Dialogpaper,
    
  ];
  const data = [
    {
      image: samsungtv,
      title: "",
      description:"",
        // "sooka is a proudly Malaysian streaming app for global and local LIVE sports, Asian entertainment, children’s entertainment and curated local Originals based on stories that we all love.They believe that entertainment has the power to bring people together and their aim is to foster connectivity amongst Malaysians by making their favourite shows accessible and affordable for all. sooka users can immerse themselves in their favourite shows anytime, anywhere, and on any device with a user-friendly and seamless streaming experience for both mobile devices and Smart TVs.",
    },
    // {
    //   image:vivre,
    //   title: "",
    //   description:"",
    //     // "Vivre is a cloud based service for service provider or content aggregator marketing team to manage TV real estate. Vivre solution proposes different modules related to TV application services.",
    // },
    // {
    //   image: hospitality,
    //   title: "",
    //   description:""
    //     // "Personalizing User Experience is a key driver of OTT app success. Horizon’s Recommendation Engine SARA uses AI and data analytics to understand user preferences and behaviour, allowing for tailored content recommendations.",
    // },
    {
      image: banner1,
      title: "",
    },
    {
      image: banner2,
      title: "",
     
    },
    {
      image: banner3,
      title: "",
     
    },
    {
      image: banner4,
      title: "",
     
    },
    {
      image: banner5,
      title: "",
     
    },
    {
      image: banner6,
      title: "",
     
    },
  ];
  const slides = [
    {
      src: sooka,
      alt: "image1 for carousel",
    },
    {
      src: vivref,
      alt: "image 2 for carousel",
    },
    {
      src: hospitalityf,
      alt: "image 3 for carousel",
    },
  ];
  return (
    <>
      <div className="cutomers-container">
        <div className="customers-title">
          <h3>Customers</h3>
          <p>
            We worked with multiple clients and ensured that our work has met
            their vision for their products. Here are some examples of some of
            our best work.
          </p>
        </div>
        <Carau logos={logos} relatedImages={relatedImages} />
        <div className="carousel-cust">
          <div className="c-title">
            <h3>Gallery</h3>
          </div>
        </div>
        <div className="single-image" style={{ marginTop: "20px" }}>
          <SingleCarousel data={data} />
        </div>
        {/* <div className="gallery">

        <div className="carousel-cust">
          <div className="c-title">
            <h3>Gallery</h3>
          </div>
        </div>
          <Carousel data={slides} />
        </div> */}
        <div className="feature-partner">
        <div className="feature-title">
            <h3>Features</h3>
          </div>
          <div className="feature">
            <img
              src={require("../../assets/Customers/Features 1.png")}
              style={{ width: "425px",height: "159px"}}
            />
             <img
              src={require("../../assets/Customers/Features 2.png")}
              style={{ width: "425px",height: "159px"}}
            />
             {/* <img
              src={require("../../assets/Customers/Features 3.png")}
              style={{ width: "425px",height: "159px"}}
            /> */}
             <img
              src={require("../../assets/Customers/Features 4.png")}
              style={{ width: "425px",height: "159px"}}
            />
          </div>
          {/* <div className="partner">
            <div className="partner-title">
              <h3>In Partnership with</h3>
            </div>
            <div className="partnrs-logo">
              <img
                src={require("../../assets/Customers/kalturab.png")}
                style={{ minWidth: "80px", height: "40px" }}
              />
              <img
                src={require("../../assets/Customers/irdeto_logo.png")}
                style={{ minWidth: "80px", height: "40px" }}
              />
            </div>
          </div> */}
        </div>
      </div>
      <NewFooter />
    </>
  );
}
