import React, { useEffect } from 'react'
import Navbar from '../components/Navbar'
import Top from '../components/Top'
import WWD from '../components/WWD'
import OTTProducts from '../components/OTTProducts'
import StartWithUs from '../components/StartWithUs'
import Clients from '../components/Clients'
import Contact from '../components/Contact'
import Careers from '../components/Careers'
import Footer from '../components/Footer'
import Navigator from '../components/Navigator'
import { Carousel } from "../components/Carousel/Carousel1/Carousel"
import { slides } from "../components/Carousel/Carousel1/data";
import imag from "../assets/Vivre.png"
import "../components/Carousel/Carousel1/Carousel"
import FrontendApp from './OttFrontend'
import Services from './PlatformSupportServices'
import PlatformSupported from './platformSupport/platforms'
import Card from '../components/Card/card'
import Banner from '../components/Banner'
import { Typography } from '@mui/material'
import OttPartners from './partners'
import MiniCarousel from '../components/MiniCarousel'
import WhoWeAre from './AboutUs/WhoWeAre'
import NewFooter from '../components/NewFooter/NewFooter'
import HeroBanner from '../components/HeroBanner/Herobanner'

export default function Home() {

  const Products = [
    {
      "src": require('../assets/Home/cropped-Vivre.png'),
      "href": "/Vivre",
      "title": "The Experience Platform"
    },
    {
      "src": require('../assets/Home/cropped-sara2.png'),
      "href": "/Sara",
      "title": "Enrich Your Experience"
    },
    {
      "src": require('../assets/Home/Hospitality.png'),
      "href": "/Hospitility",
      "title": "Horizon Hospitility Hub"
    },
    {
      "src": require('../assets/Home/cropped-Blinks.png'),
      "href": "/Blink",
      "title": "User Generated Content"
    }
  ]

  const Partners = [
    {
      "src": `${require('../assets/Home/Handshake2.png.jpg')}`,
      "href": "/Kaltura"
    },
  ]

  const Customers = [
    {
      "src": `${require('../assets/Home/cropped-Astro_logo.png')}`,
      "href": "/Astro"
    },
    {
      "src": `${require('../assets/Home/cropped-Dialog_Axiata_logo.png')}`,
      "href": "/Dialog"
    },
    {
      "src": `${require('../assets/Home/cropped-dish_logo.webp')}`,
      "href": "/DishTv"
    },
    {
      "src": `${require('../assets/Home/cropped-Emeritus_Logo.jpeg')}`,
      "href": "/Emiratus"
    },
    {
      "src": `${require('../assets/Home/cropped-Reshet_13.png')}`,
      "href": "/Reshet"
    }
  ]

  useEffect(() => {
    let x = document.getElementById("wwd")
    let y = document.getElementById("contact")

    if (window.location.hash === "#wwd")
      x.scrollIntoView()

    else if (window.location.hash === "#ott-products")
      document.getElementById("ott-products").scrollIntoView()

    else if (window.location.hash === "#swu")
      document.getElementById("swu").scrollIntoView()

    else if (window.location.hash === "#contact")
      setTimeout(() => {
        y.scrollIntoView()

      }, 500)
  }, [])

  return (
    <div
      className='relative headers  w-1/4 md:w-1/2 lg:w-[100%] min-h-screen'
    >
      <div className=' flex flex-col justify-center items-center lg:w-full'>

        <div className="relative z-10 w-full">
          <HeroBanner />
        </div>
        <div className="relative z-10 w-full p-[20px]">
          <Typography style={{
            display: "flex",
            justifyContent: "center",
            fontSize: "39px",
            fontWeight: "800",
            fontFamily: 'system-ui',
            // borderBottom: "2px solid #9ca3af"
            color: "#6054E4",
          }}>Our Partners</Typography>
          <div style={{ paddingLeft: "10rem", paddingRight: "10rem", paddingTop: "30px", paddingBottom: "35px" }}>
            <img src={require("../assets/Home/Ourpartner.png")} alt="" />
          </div>
          <div style={{ paddingLeft: "10rem", paddingRight: "10rem", paddingTop: "30px", paddingBottom: "35px" }}>
            <img src={require("../assets/Home/ibc.png")} alt="" />
          </div>
        </div>
        <div className="relative z-10 w-full">
          <NewFooter />
        </div>
      </div>
    </div>
  )
}
