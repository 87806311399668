import styled from 'styled-components';

export const Slide = styled.div`
//   transform: translateX(-1470px);
  opacity: 1;
  z-index: 92;
//   visibility: hidden;
  width: 100%;
  height: 100%;
  display: inline-block;
  position: relative;
  padding-left: 75px;
  padding-right: 75px;
  background-color: var(--space-grey-1);
  background-image: linear-gradient(1.42deg,#000 4%,transparent 44%),linear-gradient(90deg,rgba(0,0,0,.8),transparent),url('https://cdn.prod.website-files.com/63c597ccd1518e710eed86ef/6420caa922b667fd59b63d84_home-hero.webp');
  background-position: 0 0,0 0,50%;
  background-size: auto,auto,cover;
  padding-top: 0;
  z-index:1;
`;

export const ContentContainer = styled.div`
    max-width: 1480px;
    margin-left: auto;
     height:100vh;
      display: flex;
        align-items: center;

`;

export const HeroWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 60%;
    max-width: 800px;
`;

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 32px;
 
  
  @media (max-width: 600px) {
    margin-bottom: 15px;
  }
`;

export const BlueLine = styled.div`
  opacity: 1;
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
`;

export const HeroCaption = styled.p`
    margin-left: 10px;
    color:white;
    text-transform: uppercase;
    margin-bottom: 0;
    font-size: 20px;
    line-height: 145%;
    display: block;
`;

export const HeroTitle = styled.h1`
  margin-bottom: 48px;
  color: white
`;

export const Button = styled.a`
  opacity: 1;
  transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
  text-decoration: none;
  padding: 10px 20px;
  color: var(--spectralon-white);
  background-color: var(--cool-blue);
  border-radius: 5px;
   color: white
`;