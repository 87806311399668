import React from 'react'
import { BsJustify } from 'react-icons/bs'

const styles = {
  container: {
    backgroundColor: '#070532',
    color: '#fff',
    paddingBottom: '5rem',
    marginTop: '5px',
    marginBottom: '5px',
    fontFamily: 'proxima-nova,Proxima Nova,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif',
    fontSize: '1.3125rem',
    fontWeight: 300,
    lineHeight: 1.66667,
    textAlign: 'left',
    boxSizing: 'border-box',
  },
  mainHeaderText: {
    color: '#fff',
  },
  mainText: {
    color: '#9c9bad',
    fontSize: "18px",
    fontWeight: "400",
    lineHeight: "150%",
    fontStyle: "normal",
    width:"350px",

  },
  btnBlue: {
    backgroundColor: '#070532',
    color: '#fff',
    padding: '0.5rem 1rem',
    textDecoration: 'none',
    borderRadius: '4px',
    border: '1px solid #070532',
    display: 'inline-block',
  },
  logoFooterText: {
    color: '#9c9bad',
    marginBottom: '1rem',
  },
  logoLinkText: {
    marginTop: "1rem",
    display: "flex",
    flexFlow: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  iconTitle: {
    fontWeight: 'bold',
    color: '#fff',
  },
  iconText: {
    color: '#9c9bad',
    padding: 0,
    fontSize: "17px"
  },
  logoImage: {
    maxWidth: '100%',
  },
  row: {
    display: "grid",
    // flexFlow: "row",
    // gap: "10px",
    width: "100%",
    marginRight: "-15px",
    marginLeft: "-15px",
    gridTemplateColumns: "repeat(2, 1fr)"
  },
  mainContainer: {
    // display:"flex",
  },
  mainContent: {
    position: "relative",
    paddingTop: "7rem",
    paddingTop: "7rem",
    textAlign: "left",
    zIndex: "2",
    opacity: "1",
    top: '0px',
    // width: "37%",
  },
  mainHeaderText: {
    fontSize: "30px",
    fontWeight: "500",
    lineHeight: "37px",
    fontStyle: "normal",
    marginBottom: "1em",
  },
  logoContainer: {
    // width: "50%",
    display: "grid",
    paddingTop: "7rem",
    // flexWrap: "wrap",
    justifyContent: "center",
    paddingRight: "2rem",
    paddingBottom: "1rem",
    paddingLeft: "3rem",
    gridTemplateColumns: "repeat(2, 1fr)",
    gap:"20px"


  }
}

export default function PlatformSupported() {
  return (
    <div style={styles.container}>

      <div className="container main-container" >
        <div className="row" style={styles.row}>
          <div className="main-content" style={styles.mainContent}>
            <h3 className="main-header-text block-image" style={styles.mainHeaderText}>OTT Platform support</h3>
            <div className="main-text" style={styles.mainText}>
              <span style={styles.mainText}>Horizon supports all major TV connected devices and mobile platforms.</span>
            </div>
          </div>
          <div className="logo-container" style={styles.logoContainer}>
            <div className="logo-link animate-text quote-content" style={{
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
              flexFlow: "column"
            }}>
              {/* <div className="image-wrapper">
                <div className="image-container">
                  <img className="logo-image transparent-image" src="https://fxdigital.uk/app/uploads/2020/09/TV-graphic-1.png" alt="Smart TV" style={styles.logoImage} />
                </div>
              </div> */}
              <div className="logo-link-text" style={styles.logoLinkText}>
                {/* <p className="icon-title" style={styles.iconTitle}>Smart TV</p> */}
                <p className="icon-text" style={styles.iconTitle}>Samsung</p>
              </div>
            </div>
            <div className="logo-link animate-text quote-content" style={{
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
              flexFlow: "column"
            }}>
              {/* <div className="image-wrapper">
                <div className="image-container">
                  <img className="logo-image transparent-image" src="https://fxdigital.uk/app/uploads/2020/09/Group-374.png" alt="Tablets, desktops and laptop computers" style={styles.logoImage} />
                </div>
              </div> */}
              <div className="logo-link-text" style={styles.logoLinkText}>
                <p className="icon-title" style={styles.iconTitle}>LG</p>
              </div>
            </div>
            <div className="logo-link animate-text quote-content" style={{
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
              flexFlow: "column"
            }}>
              {/* <div className="image-wrapper">
                <div className="image-container">
                  <img className="logo-image transparent-image" src="https://fxdigital.uk/app/uploads/2020/09/Rectangle-128.png" alt="Mobile Devices" style={styles.logoImage} />
                </div>
              </div> */}
              <div className="logo-link-text" style={styles.logoLinkText}>
                {/* <p className="icon-title" style={styles.iconTitle}>Mobile Devices</p> */}
                <p className="icon-text" style={styles.iconTitle}>Android TV</p>
              </div>
            </div>
            <div className="logo-link animate-text quote-content" style={{
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
              flexFlow: "column"
            }}>
              {/* <div className="image-wrapper">
                <div className="image-container">
                  <img className="logo-image transparent-image" src="https://fxdigital.uk/app/uploads/2020/09/Rectangle-128.png" alt="Mobile Devices" style={styles.logoImage} />
                </div>
              </div> */}
              <div className="logo-link-text" style={styles.logoLinkText}>
                <p className="icon-title" style={styles.iconTitle}>Fire TV</p>
                {/* <p className="icon-text" style={styles.iconText}> (i.e. Android and iOS mobile devices)</p> */}
              </div>
            </div>
            <div className="logo-link animate-text quote-content" style={{
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
              flexFlow: "column"
            }}>
              {/* <div className="image-wrapper">
                <div className="image-container">
                  <img className="logo-image transparent-image" src="https://fxdigital.uk/app/uploads/2020/09/Rectangle-128.png" alt="Mobile Devices" style={styles.logoImage} />
                </div>
              </div> */}
              <div className="logo-link-text" style={styles.logoLinkText}>
                <p className="icon-title" style={styles.iconTitle}>Apple TV</p>
                {/* <p className="icon-text" style={styles.iconText}> (i.e. Android and iOS mobile devices)</p> */}
              </div>
            </div>
            <div className="logo-link animate-text quote-content" style={{
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
              flexFlow: "column"
            }}>
              {/* <div className="image-wrapper">
                <div className="image-container">
                  <img className="logo-image transparent-image" src="https://fxdigital.uk/app/uploads/2020/09/Rectangle-128.png" alt="Mobile Devices" style={styles.logoImage} />
                </div>
              </div> */}
              <div className="logo-link-text" style={styles.logoLinkText}>
                <p className="icon-title" style={styles.iconTitle}>IOS</p>
                {/* <p className="icon-text" style={styles.iconText}> (i.e. Android and iOS mobile devices)</p> */}
              </div>
            </div>
            <div className="logo-link animate-text quote-content" style={{
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
              flexFlow: "column"
            }}>
              {/* <div className="image-wrapper">
                <div className="image-container">
                  <img className="logo-image transparent-image" src="https://fxdigital.uk/app/uploads/2020/09/Rectangle-128.png" alt="Mobile Devices" style={styles.logoImage} />
                </div>
              </div> */}
              <div className="logo-link-text" style={styles.logoLinkText}>
                <p className="icon-title" style={styles.iconTitle}>Vidaa</p>
                {/* <p className="icon-text" style={styles.iconText}> (i.e. Android and iOS mobile devices)</p> */}
              </div>
            </div>
            <div className="logo-link animate-text quote-content" style={{
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
              flexFlow: "column"
            }}>
              {/* <div className="image-wrapper">
                <div className="image-container">
                  <img className="logo-image transparent-image" src="https://fxdigital.uk/app/uploads/2020/09/Rectangle-128.png" alt="Mobile Devices" style={styles.logoImage} />
                </div>
              </div> */}
              <div className="logo-link-text" style={styles.logoLinkText}>
                <p className="icon-title" style={styles.iconTitle}>Dongle</p>
                {/* <p className="icon-text" style={styles.iconText}> (i.e. Android and iOS mobile devices)</p> */}
              </div>
            </div>
            <div className="logo-link animate-text quote-content" style={{
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
              flexFlow: "column"
            }}>
              {/* <div className="image-wrapper">
                <div className="image-container">
                  <img className="logo-image transparent-image" src="https://fxdigital.uk/app/uploads/2020/09/Rectangle-128.png" alt="Mobile Devices" style={styles.logoImage} />
                </div>
              </div> */}
              <div className="logo-link-text" style={styles.logoLinkText}>
                <p className="icon-title" style={styles.iconTitle}>Settop Box</p>
                {/* <p className="icon-text" style={styles.iconText}> (i.e. Android and iOS mobile devices)</p> */}
              </div>
            </div>
            <div className="logo-link animate-text quote-content" style={{
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
              flexFlow: "column"
            }}>
              {/* <div className="image-wrapper">
                <div className="image-container">
                  <img className="logo-image transparent-image" src="https://fxdigital.uk/app/uploads/2020/09/Rectangle-128.png" alt="Mobile Devices" style={styles.logoImage} />
                </div>
              </div> */}
              <div className="logo-link-text" style={styles.logoLinkText}>
                <p className="icon-title" style={styles.iconTitle}>Web / Mobile Web</p>
                {/* <p className="icon-text" style={styles.iconText}> (i.e. Android and iOS mobile devices)</p> */}
              </div>
            </div>
            
          </div>
        </div>
        {/* <div className="logo-footer-content col-12 col-md-4">
              <div className="logo-footer-text" style={styles.logoFooterText}>Looking to build an OTT application?</div>
              <div className="logo-footer-link">
                <a className="btn btn-blue component-buttons" href="https://fxdigital.uk/contact/" role="button" style={styles.btnBlue}>Drop us a line</a>
              </div> */}
        {/* </div> */}
      </div>

    </div>
  )
}
