import React, { useState, useRef, useEffect } from "react";
import "./resources.css";
import { Carousel } from "../../components/Carousel/Carousel1/Carousel";
import vivre from "../../assets/solutions/Vivre.png";
import sia from "../../assets/resources/White paper.png";
import styled from "styled-components";
import Blogs from "./Blogs";
import NewFooter from "../../components/NewFooter/NewFooter";
import { PDF_URL } from "./Contants";

const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 900px;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  margin: 20px auto;
`;

const CardSection = styled.div`
  flex: 1;
  padding: 20px;
  box-sizing: border-box;
`;

const LeftSection = styled(CardSection)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #3327b9;
  text-align: start;
`;

const Divider = styled.div`
  width: 1px;
  background-color: #ddd;
`;

const Title = styled.h2`
  margin: 0 0 0px;
  font-size: 1.5em;
`;

const Description = styled.p`
  margin: 0 0 20px;
  font-size: 1em;
  color: #3327b9;
`;
const Para = styled.p`
  margin: 0 0 20px;
  font-size: 1em;
  color: #ffff;
`;
const Input = styled.input`
  padding: 10px;
  margin: 5px 0;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const Button = styled.button`
  padding: 10px 20px;
  margin-top: 10px;
  width: 30%;
  background-color: #6054e4;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #6054e4;
  }
`;
export default function Resources() {
    const formRef = useRef(null);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState();
    const [activeSlide, setActiveSlide] = useState("");
     const whitepapers = [
        {
            id: "0",
            src: sia,
            alt: "image1 for carousel",
        },
        // {
        //     id: "1",
        //     src: vivre,
        //     alt: "image 2 for carousel",
        // },
    ];
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const handleSubmit = (e) => {
        e.preventDefault();
        const phoneNumberPattern = /^\d{10}$/;
        if (!phoneNumberPattern.test(phoneNumber)) {
            alert("Please enter a valid 10-digit phone number.");
            return; 
        }
       
        // Handle form submission logic here
        console.log("Name:", name);
        console.log("Email:", email);
        downloadInvoice()
    };
    const handleItemSelect = (index ,type) => {
       
        if (type ==="nextslide"&&index==0) {
            setActiveSlide("whitepaper1");
        }else if (type ==="nextslide"&& index==1) {
            setActiveSlide("whitepaper2");
        }else{
            console.log('Selected Item ID:', index);
            setActiveSlide(index)
            const offset = 5;
            formRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
            window.scrollBy(0, 350);
        }


    };
    const handleSlideChange = (index) => {
       
        
        console.log("Active Slide Index:", index);
    };

   
    const downloadInvoice = () => {
        console.log(activeSlide, name, email, companyName, phoneNumber);

        fetch(
            PDF_URL,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    id: activeSlide==="0"?"whitepaper1":"whitepaper2",
                    name: name,
                    email: email,
                    companyName: companyName,
                }),
            }
        )
        .then((response) => {
            if (!response.ok) {
                // Handle non-2xx HTTP responses
                throw new Error(`Error: ${response.status} ${response.statusText}`);
            }
            return response.json();
        })
        .then((data) => {
            console.log("Response data:", data);
            if (data?.responseCode == 1) {
                downloadPDF(data,activeSlide);
            } else {
                console.log("Error in response:", data);
            }
        })
        .catch((error) => {
            // Handle network errors or issues with the fetch request
            console.error("API call failed:", error);
            alert("Failed to download the whitepaper. Please try again later.");
        });
        
    };

    const downloadPDF = (responseData, pdfName) => {
        const byteCharacters = atob(responseData?.file);
        const byteNumbers = new Array(byteCharacters?.length);
        for (let i = 0; i < byteCharacters?.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], {
            type: "application/pdf",
        });

        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = activeSlide==="0"?"whitepaper1":"whitepaper2" + ".pdf";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    };


    return (
        <div className="res-continer">
            <div className="res-section1">
                <div className="res-title">
                    <h3>Resources</h3>
                </div>
                {/* <div className="res-description"> */}
                    {/* <p>
                        We worked with multiple clients and ensured that our work has met
                        their vision for their products. Here are some examples of some of
                        our best work.
                    </p> */}
                {/* </div> */}

                <div className="form-carousel">
                    <h3 >White Paper</h3>
                    <Carousel data={whitepapers} onItemSelect={handleItemSelect} page={"resource"} />
                    <div className="form-section" ref={formRef}>
                        <CardContainer>
                            <LeftSection>
                                <Title>Want access to Whitepaper?</Title>
                                <Title>Sign up today!</Title>
                                <Description>
                                    Sign up today! Fill the form and we’ll get in touch with you
                                    about how you can integrate Whitepaper with your business.
                                </Description>
                            </LeftSection>
                            <Divider />
                            <CardSection>
                                <form onSubmit={handleSubmit}>
                                    <Input
                                        type="text"
                                        placeholder="Name"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        required
                                    />
                                    <Input
                                        type="email"
                                        placeholder="Email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                    />
                                    <Input
                                        type="text"
                                        placeholder="Company Name"
                                        value={companyName}
                                        onChange={(e) => setCompanyName(e.target.value)}
                                        required
                                    />
                                    <Input
                                        type="text"
                                        placeholder="Phone Number"
                                        value={phoneNumber}
                                        onChange={(e) => setPhoneNumber(e.target.value)}
                                        required
                                    />
                                    <Button type="submit">Submit</Button>
                                </form>
                            </CardSection>
                        </CardContainer>
                    </div>
                </div>
            </div>
            <div className="res-section2">
                <div className="res-title">
                    <h3>Blogs</h3>
                </div>
                <div className="res-description">
                    <p>
                        Keep up with the latest news in the OTT industry and also stay to
                        date with latest news about our Company.
                    </p>
                </div>
            </div>
            <div className="blogs">
                <Blogs />
            </div>
            {/* <div className="press">
                <div className="press-title">
                    <h3>Press Releases</h3>
                </div>
                <div className="press-description">
                    <p>
                        Keep up with the latest news in the OTT industry and also stay to
                        date with latest news about our Company.
                    </p>
                </div>
                <div className="release-container">
                    <div className="first-release">
                        <div className="first-release-title">
                            <h3>Lorem ipsum dolor sit amet, consectetur </h3>
                        </div>
                        <div className="first-release-description">
                            <p>
                                Keep up with the latest news in the OTT industry and also stay
                                to date with latest news about our Company.
                            </p>
                            <button className="readmore-btn">Read More</button>
                        </div>
                    </div>
                    <div className="divider"></div>
                    <div className="first-release">
                        <div className="first-release-title">
                            <h3>Lorem ipsum dolor sit amet, consectetur </h3>
                        </div>
                        <div className="first-release-description">
                            <p>
                                Keep up with the latest news in the OTT industry and also stay
                                to date with latest news about our Company.
                            </p>
                            <button className="readmore-btn">Read More</button>
                        </div>
                    </div>
                    <div className="divider"></div>
                    <div className="first-release">
                        <div className="first-release-title">
                            <h3>Lorem ipsum dolor sit amet, consectetur </h3>
                        </div>
                        <div className="first-release-description">
                            <p>
                                Keep up with the latest news in the OTT industry and also stay
                                to date with latest news about our Company.
                            </p>
                            <button className="readmore-btn">Read More</button>
                        </div>
                    </div>
                    <div className="divider"></div>
                </div>
            </div> */}

            <NewFooter />
        </div>
    );
}
