import React, { useState } from "react"



const Kaltura = () => {
  const [main, setMain] = useState(true)
  const [box1, setBox1] = useState(false)
  const [box2, setBox2] = useState(false)

  return (
    <>
      {main && <div className="absolute w-full h-[100vh] flex justify-center gap-10 items-center bg-slate-400">
        <div className="w-[40%] h-[40%] bg-slate-700 hover:brightness-110 transition-all duration-300 rounded-3xl p-2" onClick={() => { setBox1(true); setMain(false) }}>
          <h1 className="text-white text-start mt-6 text-3xl">SOLUTION PARTNERS</h1>
          <h1 className="text-white text-start mt-8 text-xl">App Integrtion Publishers</h1>
          <h5 className="text-white text-start mt-4 text-lg">Highly skilled and certified partners who can develop and implement our products</h5>
        </div>
        <div className="w-[40%] h-[40%] bg-slate-700 hover:brightness-110 transition-all duration-300 rounded-3xl p-2" onClick={() => { setBox2(true); setMain(false) }}>
          <h1 className="text-white text-start mt-6 text-3xl">TECHNOLOGY PARTNERS</h1>
          <h1 className="text-white text-start mt-8 text-xl">Get Help with an implementation</h1>
          <h5 className="text-white text-start mt-4 text-lg">The developers and publishers behind our integrations, helping you do more with our products</h5>
        </div>
      </div>}
      {box1 &&
        <div className="absolute w-full h-[100vh] flex justify-center gap-10 items-center">
          <div className="relative w-full h-[500px]">
            <div className="absolute top-2 right-2 text-2xl font-bold" onClick={() => { setBox1(false); setMain(true) }}>X</div>
            <div className="w-full h-[500px] bg-red-400"></div>
          </div>
        </div>}
      {box2 &&
        <div className="absolute w-full h-[100vh] flex justify-center gap-10 items-center">
          <div className="relative w-full h-[500px]">
            <div className="absolute top-2 right-2 text-2xl font-bold" onClick={() => { setBox2(false); setMain(true) }}>X</div>
            <div className="w-full h-[550px] bg-blue-400 flex flex-col justify-center items-center ">
              <h2 className="text-black text-center text-2xl mb-8">TECHNOLOGY PARTNERS</h2>
              <div className="grid grid-cols-4 gap-x-20 gap-y-20  justify-center items-center">
                <div className="bg-blue-400 w-[100px] h-[100px] rounded-full flex flex-col justify-center items-center">
                  <img src={require("../assets/IOS_Google_icon.png")} alt="" className="object-cover" />
                  <span>Goolge</span>
                </div>
                <div className="w-[100px] h-[100px] rounded-full flex flex-col justify-center items-center">
                  <img src={require("../assets/Conviva.png")} alt="" className="object-cover w-[100px] h-[100px] rounded-full" />
                  <span>Conviva</span>
                </div>
                <div className="w-[100px] h-[100px] rounded-full flex flex-col justify-center items-center">
                  <img src={require("../assets/clevertap.png")} alt="" className="object-cover w-[100px] h-[100px] rounded-full" />
                  <span>Clevertap</span>
                </div>
                <div className="w-[100px] h-[100px] rounded-full flex flex-col justify-center items-center">
                  <img src={require("../assets/prime.png")} alt="" className="object-cover w-[100px] h-[100px] rounded-full" />
                  <span>Prime</span>
                </div>
                <div className="w-[100px] h-[100px] rounded-full flex flex-col justify-center items-center">
                  <img src={require("../assets/SDMC.png")} alt="" className="object-cover w-[100px] h-[100px] rounded-full" />
                  <span>SDMC</span>
                </div>
                <div className="w-[100px] h-[100px] rounded-full flex flex-col justify-center items-center">
                  <img src={require("../assets/aws10.png")} alt="" className="object-cover w-[100px] h-[100px] rounded-full" />
                  <span>AWS</span>
                </div>
                <div className="w-[100px] h-[100px] rounded-full flex flex-col justify-center items-center">
                  <img src={require("../assets/brightcove10.png")} alt="" className="object-cover w-[100px] h-[100px] rounded-full" />
                  <span>Brightcove</span>
                </div>
                <div className="bg-red-500 w-[100px] h-[100px] rounded-full justify-center items-center"></div>
                <div className="bg-red-500 w-[100px] h-[100px] rounded-full justify-center items-center"></div>
                <div className="bg-red-500 w-[100px] h-[100px] rounded-full justify-center items-center"></div>
                <div className="bg-red-500 w-[100px] h-[100px] rounded-full justify-center items-center"></div>
                <div className="bg-red-500 w-[100px] h-[100px] rounded-full justify-center items-center"></div>
              </div>

            </div>
          </div>
        </div>}
    </>
  )
}

export default Kaltura