import React from "react";
import "./whoweare.css";
import { Typography } from "@mui/material";
import { Description } from "@mui/icons-material";

const WhoWeAre = () => (
  <div className="wcontainer">
    <div className="wrapper ">
      {/* <div
        className=""
        style={{
          display: "flex",
          justifyContent: "center",
          flexFlow: "column",
          justifyContent: "center",
          flexFlow: "column",
          alignItems: "center",
          paddingLeft: "10px",
          paddingRight: "10px",
        }}
      >
        <div className="wrapper-title">Who we are</div>
        <div className="wrapper-description pl-[8rem] pr-[8rem]">
          We offer user experience and recommendations driven by AI product
          solutions that caters to OTT, Enterprise customer. We have carved
          niche experience in OTT solution delivery with a shorter time to
          market off-the-shelf solutions with multiscreen experience.
        </div>
      </div>
      <div className="wrapper-group-visuals">
        <img src={require("../../../assets/whoweare/Hero image.png")} />
      </div> */}
      <div className="bottom-asset">
        {/* <img src={require("../../../assets/aboutus/ourmission.png")} /> */}

        <img src={require("../../../assets/aboutus/Core Values (2).png")} />
      </div>
      <div className="history-section">
        <div className="history-info">
          <h3>Our History</h3>
        </div>
      </div>
      <div className="horizon">
        <img src={require("../../../assets/aboutus/history.png")} />
        <img src={require("../../../assets/aboutus/worldm.png")} />
      </div>

      <div className="applynow">
        <a
          className="elementor-button elementor-button-link elementor-size-sm"
          href="mailto:hr.bangalore@horizonind.org?subject=Join%20Us"
        >
          <img
            src={require("../../../assets/aboutus/Recommendations.png")}
            className="applybtn"
          />
        </a>
      </div>
    </div>
  </div>
);

export default WhoWeAre;
