import React, { useState } from 'react';
import { styled } from '@mui/system';
import { Button } from '@mui/material';

// Container for the carousel
const CarouselContainer = styled('div')`
  position: relative;
  width: 100%;
  max-width: 800px; /* Adjust as needed */
  margin: 0 auto; /* Center the carousel */
  overflow: hidden;
`;

// Wrapper that contains all slides
const CarouselWrapper = styled('div')`
  display: flex;
  transition: transform 0.5s ease-in-out;
  width: ${({ itemCount }) => `${itemCount * 100}%`}; /* Set width to fit all slides */
  transform: ${({ translateX }) => `translateX(-${translateX}px)`}; /* Move slides horizontally */
`;

// Individual slide
const Slide = styled('div')`
  flex: 0 0 100%; /* Each slide takes up 100% of the container width */
  box-sizing: border-box;
  position: relative;
`;

// Image styling
const SlideImage = styled('img')`
  width: 100%;
  height: auto;
  object-fit: cover; /* Cover the container, maintaining aspect ratio */
`;

// Dots for navigation
const DotsContainer = styled('div')`
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 8px;
`;

// Individual dot
const Dot = styled('div')`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${({ active }) => (active ? 'black' : 'lightgray')};
  cursor: pointer;
`;

// Button styling
const ButtonStyled = styled(Button)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
`;

// Previous button
const PrevButton = styled(ButtonStyled)`
  left: 10px;
`;

// Next button
const NextButton = styled(ButtonStyled)`
  right: 10px;
`;

const CarouselGallery = ({ data }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % data.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + data.length) % data.length);
  };

  // Calculate the amount to translate the wrapper
  const translateX = currentIndex * (100 / data.length);

  return (
    <CarouselContainer>
      <CarouselWrapper itemCount={data.length} translateX={translateX}>
        {data.map((image, index) => (
          <Slide key={index}>
            <SlideImage src={image} alt={`Slide ${index}`} />
          </Slide>
        ))}
      </CarouselWrapper>
      <DotsContainer>
        {data.map((_, index) => (
          <Dot
            key={index}
            active={index === currentIndex}
            onClick={() => setCurrentIndex(index)}
          />
        ))}
      </DotsContainer>
      <PrevButton onClick={handlePrev}>Prev</PrevButton>
      <NextButton onClick={handleNext}>Next</NextButton>
    </CarouselContainer>
  );
};

export default CarouselGallery;
