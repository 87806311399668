import React, { useState, useEffect } from 'react';
import './minicarousel.css';

const images = [
  {
    src: require("../../assets/IOS_Google_icon.png"),
    description: '“Working with Dr. Mathew Thomas has been a game-changer for me personally and professionally. His leadership coaching services have helped me unlock my potential, refine my leadership style and navigate'
  },
  {
    src: require("../../assets/partners/images.png"),
    description: '“I can’t speak highly enough of Dr. Mathew Thomas and the impact his coaching services have had on our organization. His keen insights, practical advice and genuine commitment to our'
  },
  {
    src:require("../../assets/SDMC.png"),
    description: '“Mathew put together one of the best programs I have attended in 15 years. I walked away with motivation and renewed energy.”'
  },
  
];

const MiniCarousel = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 4000); // Change image every 3 seconds
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="carousel">
      <div className="carousel-images">
        {images.map((image, index) => (
          <img
            key={index}
            src={image.src}
            alt={`Image ${index + 1}`}
            className={`carousel-image ${index === activeIndex ? 'active' : 'inactive'}`}
          />
        ))}
      </div>
      <div className={`carousel-description ${'description-animation'}`} style={{ fontFamily: 'ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"' }}>
        {images[activeIndex].description}
      </div>
    </div>
  );
};

export default MiniCarousel;
