import React from 'react';
import Top from '../components/Top';
import Footer from '../components/Footer';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const Main = styled.main`
  flex: 1;
  // padding-top:84px;
  
`;

const Layout = (WrappedComponent) => {
  return (props) => (
    <Container>
      <Top />
      <Main>
        <WrappedComponent {...props} />
      </Main>
      {/* <Footer /> */}
    </Container>
  );
};

export default Layout;
