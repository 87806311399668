import React, { useEffect } from 'react'
import { MAIN_RESPONSIVE } from './styles/app'
import { Routes, Route } from "react-router"
import Home from './pages/Home'
import AboutUs from './pages/AboutUs/index'
import Astro from './pages/Astro'
import Blink from './pages/Blinks/Blinks'
import Dialog from './pages/Dialog'
import DishTv from './pages/DishTv'
import Emiratus from './pages/Emiratus'
import Hospitilty from './pages/hospitality/index'
import Kaltura from './pages/Kaltura'
import Products from './pages/Products'
import Reshet from './pages/Reshet'
import Sara from './pages/SaraPage/Sara'
import Solution from './pages/Solutions'
import Vivre from './pages/vivreProduct/VivrePage'
import Xyz from './pages/Xyz'
import Terms from './pages/Terms'
import Privacy from './pages/Privacy'
import OttAppPage from './pages/OttAppsFe'
import Layout from './layout/Layout'
import Customers from './pages/Customers'
import Resources from './pages/ResourcesPage'
import GamificationBlog from './pages/Blogs/Gamification'
import BlogPage from './pages/Blogs/ContentDiscovery'
import BlogPagePersonalization from './pages/Blogs/ContentDiscoveryPersonalization'
import TermsAndConditions from './pages/TermaAndConditions'
import BlogPageAutomation from './pages/Blogs/Automation'
import PrivacyPolicy from './pages/PrivacyPolicy'
import ProductsPage from './pages/Products'
import AllProducts from './pages/ProductsPage'

const HomePageWithLayout = Layout(Home);
const SolutionsPageWithLayout = Layout(Solution);
const ProductsPageWithLayout = Layout(Sara);
// const ReferencesPageWithLayout = Layout(ReferencesPage);
const AboutPageWithLayout = Layout(AboutUs);
const CustomersWithLayout = Layout(Customers)
const ResoucesWithLayout = Layout(Resources)
const BlinksWithLayout = Layout(Blink);
const VivreWithLayout = Layout(Vivre);
const HospitalityWithLayout = Layout(Hospitilty);
const AllProductsWithLayout = Layout(AllProducts);

export default function App() {

  useEffect(() => {
    const afterResourcesLoaded = () => {
      console.log("All resources have been loaded.");
      // Perform your operations here.
    };

    // Listen for the 'load' event on the window object.
    window.addEventListener('load', afterResourcesLoaded);

    // Clean up the event listener when the component unmounts.
    return () => {
      window.removeEventListener('load', afterResourcesLoaded);
    };
  }, []); // The empty dependency array means this effect runs only once, like 'componentDidMount'.

  return (
    <div className={MAIN_RESPONSIVE}>
      <Routes>
        <Route exact path='/' element={<HomePageWithLayout />} />
        <Route exact path='/about' element={<AboutPageWithLayout />} />
        <Route path='*' element={<HomePageWithLayout />} />
        <Route exact path='/Astro' element={<Astro />} />
        <Route exact path='/product/blink' element={<BlinksWithLayout />} />
        <Route exact path='/Dialog' element={<Dialog />} />
        <Route exact path='/DishTv' element={<DishTv />} />
        <Route exact path='/Emiratus' element={<Emiratus />} />
        <Route exact path='/product/hospitality' element={<HospitalityWithLayout />} />
        <Route exact path='/Kaltura' element={<Kaltura />} />
        <Route exact path='/Products' element={<AllProductsWithLayout />} />
        <Route exact path='/Dialog' element={<Dialog />} />
        <Route exact path='/Reshet' element={<Reshet />} />
        <Route exact path='/product/Sara' element={<ProductsPageWithLayout />} />
        <Route exact path='/Solutions' element={<SolutionsPageWithLayout />} />
        <Route exact path='/product/Vivre' element={<VivreWithLayout />} />
        <Route exact path='/Xyz' element={<Xyz />} />
        <Route exact path='/Terms' element={<Terms />} />
        <Route exact path='/Privacy' element={<Privacy />} />
        <Route exact path='/ott-app-development' element={<OttAppPage />} />
        <Route exact path='/Customers' element={<CustomersWithLayout />} />
        <Route exact path='/resources' element={<ResoucesWithLayout />} />
        <Route exact path='/gamification' element={<GamificationBlog/>} />
        <Route exact path='/contentDiscoverySearch' element={<BlogPage/>} />
        <Route exact path='/BlogPagePersonalization' element={<BlogPagePersonalization/>} />
        <Route exact path='/terms&conditions' element={<TermsAndConditions/>} />
        <Route exact path='/BlogPageAutomation' element={<BlogPageAutomation/>} />
        <Route exact path='/PrivacyPolicy' element={<PrivacyPolicy/>} />
      </Routes>
    </div>
  )
}
