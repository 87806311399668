import React from "react";
import { ReactComponent as Location } from "../assets/location.svg";
import { ReactComponent as Call } from "../assets/call.svg";
import { ReactComponent as Mail } from "../assets/mail.svg";
import facebookIcon from "../assets/facebook.png";
import youtubeIcon from "../assets/youtube.png";
import FaLinkedinIcon from "../assets/LinkedIn.svg";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { ReactComponent as Linkedin } from "../assets/LinkedIn.svg";

export default function Footer({ color }) {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  return (
    <>
      <div
        className={`flex w-[100%] ${color ? "bg-[#2D1347]" : "bg-[#0047AB]"}`}
      >
        <div className="py-4 w-[25%] text-center">
          <p className="text-sm text-white justify-center py-3">
            © {currentYear} All rights reserved Horizon Broadband Pvt. Ltd.
          </p>
        </div>

        <div className="py-4 w-[25%] text-center">
          <a href="/Terms">
            <p className="text-sm text-white justify-center py-3">
              Terms and Conditions
            </p>
          </a>
        </div>

        <div className="py-4 w-[25%] text-center">
          <a href="/Privacy">
            <p className="text-sm text-white justify-center py-3">
              Privacy and Policy
            </p>
          </a>
        </div>

        <div className="py-3 md:mt-0 p-3 flex justify-center gap-4 items-center rounded-t-xl md:rounded-none text-center w-[25%] md:border-r border-gray-500">
          <img src={facebookIcon} alt="" className="w-[30px] h-[30px]" />
          <img src={youtubeIcon} alt="" className="w-[30px] h-[30px]" />
          <a href="https://www.linkedin.com/company/horizon-broadband-llp/">
            <div className="bg-blue-700 rounded-full w-[30px] h-[30px] flex justify-center items-center">
              <div>
                <Link
                  to={"https://www.linkedin.com/company/horizon-broadband-llp/"}
                  className="fill-white"
                >
                  <Linkedin />
                </Link>
              </div>
            </div>
          </a>
        </div>
      </div>
    </>
  );
}
