import React from "react";
import "./herobanner.css"
import { Button, Typography } from "@mui/material";
import { Description } from "@mui/icons-material";
import Card from "../Card/card";
import FrontendApp from "../../pages/OttFrontend";


const HeroBanner = () => (
  <div className="wcontainer">
    <div className="wrapper ">
      <div className="wrapper-group-visuals">
        <img src={require("../../assets/Home/HeroBanner2.png")} alt="Hero Banner" className="hero-image" />
        <div className="text-overlay">
          <div className="book-demo">
            <a
              className="elementor-button elementor-button-link elementor-size-sm"
              href="mailto:ottsales@horizonind.org?subject=Book%20A%20Demo"
            >
              <button variant="outlined" className="demo-btn">Book a Demo</button>
            </a>
          </div>
        </div>
      </div>
      <div className="relative z-10 w-full" style={{
        margin: '10px',
        perspective: '700px',
        maxWidth: '100%',
        // background: 'linear-gradient(to top, rgba(103, 58, 183, 0.6) 0%, rgb(167 167 167 / 14%) 50%)',
        padding: '20px',
        borderRadius: '10px',
        // boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)'
      }}>
        <Typography style={{
          display: "flex",
          justifyContent: "center",
          fontSize: "39px",
          fontWeight: "800",
          fontFamily: 'system-ui',
          // borderBottom: "2px solid #9ca3af"
          color: "#6054E4",
        }}>Our Products</Typography>
        <p className='text-center color-[#555555] ' style={{ fontSize: "24px" }}>We design, Develop and Integrate Multiscreen Solutions.</p>
        <Card />
      </div>
      <div className="relative z-10 w-full">
          <FrontendApp />
        </div>
      {/* <div className="mission-container">
        <div className="mission">
          <h3>Our Milestones</h3>
        </div>
        <div className="group">
          <div className="g">
            <h3 className="g-title color-[#555555]">1.5m</h3>
            <p>SmartTV</p>
          </div>
          <div className="g">
            <h3 className="g-title color-[#555555]">5m</h3>
            <p> Mobile</p>
          </div>
          <div className="g">
            <h3 className="g-title color-[#555555]">0.5m</h3>
            <p>SetTop Box/Dongle</p>
          </div>
        </div>
      </div> */}

    </div>
  </div>
);

export default HeroBanner;
