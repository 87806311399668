import React, { useState } from "react";
import "./styles/frontenApp.css";
import { useNavigate } from "react-router";
import styled from "styled-components";
import tablet from "../assets/whoweare/Tablet icon.png";
import mobile from "../assets/whoweare/Mobile icon.png";
import dongle from "../assets/whoweare/USB icon.png";
import stb from "../assets/whoweare/STB icon.png";
import tv from "../assets/whoweare/TV icon.png";
import computer from "../assets/whoweare/Computer icon.png";
// -------
import iphone from "../assets/whoweare/iPhone.png";
import ipad from "../assets/whoweare/iPad.png";
import dongleDevice from "../assets/whoweare/Dongle.png";
import laptop from "../assets/whoweare/Macbook mockup 1.png";
import tvView from "../assets/whoweare/Samung-tv-mockup-3 1.png";
import setupBox from "../assets/whoweare/Setupbox.png";

const styles = {
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backdropFilter: "blur(3px)",
    zIndex: 0,
  },
};
const images = {
  tv: tvView,
  mobile: iphone,
  tablet: ipad,
  laptop: laptop,
  dongle: dongleDevice,
  stb: setupBox,
};

const Container = styled.div`
  display: flex;
  height: 100vh;
`;

const ImageSection = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Image = styled.img``;

const TabsSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  // justify-content: center;
  align-items: flex-start;
  padding: 20px;
  color: "#fff";
`;
const Tab = styled.button`
  //   padding: 10px 20px;
  margin: 10px;
  border: none;
  background-color: ${({ isActive }) => (isActive ? "#007BFF" : "#f0f0f0")};
  color: ${({ isActive }) => (isActive ? "#fff" : "#000")};
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  font-size: 16px;
  border-radius: 50%;

  &:hover {
    background-color: #007bff;
    color: #fff;
  }
`;
const FrontendApp = () => {
  const navigate = useNavigate();

  const [selectedTab, setSelectedTab] = useState("tv");

  const handleChange = (tab) => {
    setSelectedTab(tab);
  };
  return (
    <div
      // onClick={() => navigate("/ott-app-development")}
      style={{
        cursor: "pointer",
        // marginTop:"90px"
      }}
    >
      <div className="ott-frontend block-page-header-general alignfalse theme-red overlay-grey backdrop-blur-sm  top-0 left-0 w-full h-full z-10 absolute">
        <div className="overlay" style={styles.overlay}></div>
        <div className="container inner-content relative">
          <div className="module__inner">
            <div className="pf-logos">
              <img src={require("../assets/whoweare/Plaform logos.png")} />
            </div>
            <div
              className=""
              style={{ minHeight: "460px", overflow: "hidden" }}
            >
              <ImageSection>
                <Image
                  src={images[selectedTab]}
                  alt={selectedTab}
                  style={{ height: "460px !important" }}
                />
              </ImageSection>
            </div>
          </div>
        </div>
        <div className="container inner-content relative ">
          <div className="" style={{ color: "#FFFF" }}>
            <h2 className="" style={{ display: "flex", color: "#FFFF" }}>
              <div
                className="heading-holder"
                style={{
                  // marginRight: "10px"
                  color: "#FFFF",
                }}
              >
                OTT Frontend Application
              </div>
            </h2>
            <p className="">
              <span class="animate-text">
                Our expertise in Smart TV app development for nearly a decade of
                successful deployment experience makes us trusted Goto Partner.
                We understand complexity and challenges involved in application
                certification process and enable customers for shorter
                deployment cycle. It is key for Smart TV app development to
                stream across multiple territories and devices. At Horizon
                Broadband, we launch the application onto many devices which
                allows engaging user experience. We work in various SmartTV app
                development to ensure a multifaceted business usecase and
                delivery. We are passionate about viewer experience and excited
                about turn key solution that we offer. To find out more about
                who we are, please visit the About Us section.
              </span>
            </p>
          </div>
          <div className="tbs">
            <TabsSection>
              <Tab
                isActive={selectedTab === "tv"}
                onClick={() => handleChange("tv")}
              >
                <img src={tv} />
              </Tab>
              <Tab
                isActive={selectedTab === "laptop"}
                onClick={() => handleChange("laptop")}
              >
                <img src={computer} />
              </Tab>
              <Tab
                isActive={selectedTab === "mobile"}
                onClick={() => handleChange("mobile")}
              >
                <img src={mobile} />
              </Tab>
              <Tab
                isActive={selectedTab === "tablet"}
                onClick={() => handleChange("tablet")}
              >
                <img src={tablet} />
              </Tab>
              <Tab
                isActive={selectedTab === "dongle"}
                onClick={() => handleChange("dongle")}
              >
                <img src={dongle} />
              </Tab>
              <Tab
                isActive={selectedTab === "stb"}
                onClick={() => handleChange("stb")}
              >
                <img src={stb} />
              </Tab>
            </TabsSection>
          </div>
        </div>
      </div>
    </div>
  );
};
export default FrontendApp;
