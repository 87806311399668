import React from "react";
import "./vivre.css";
import OverlayCard from "../../components/OverlayCard";
import NewFooter from "../../components/NewFooter/NewFooter";
import { Carousel } from "../../components/VerticalCarousel";
import vivre from "../../assets/vivre/main.png";
import sia from "../../assets//solutions/sia.png";
import image1 from "../../assets/vivre/image 62.png"
import image11 from "../../assets/vivre/image 63.png"
import b1 from "../../assets/vivre/viv1.png"
import b2 from "../../assets/vivre/viv2.png"
import b3 from "../../assets/vivre/viv3.png"

export default function Vivre() {
  const whitepapers = [
    {
      id: 1,
      src: vivre,
      alt: "image1 for carousel",
    },
    {
      id: 2,
      src: image1,
      alt: "image 2 for carousel",
    },
    {
      id: 2,
      src: image11,
      alt: "image 2 for carousel",
    },
  ];
  const blogs = [
    { title: 'Easy Control', description: 'Gain easy control of your content distribution, with all the necessary actions available in one place. ', name: '', image: b1 },
    { title: 'Flexible', description: 'Customize your content as per your own preferences at any moment with just a few clicks.', name: '', image: b2 },
    { title: 'Informative', description: 'Get live analytical data about your platform/content’s performance to plan out future decisions.', name: '', image: b3 },
  
  ];
  return (
    <div className="vivre-main">
      <div className="vivre-container">
        <div className="vivre-logo">
          <img src={require("../../assets/vivre/HospitalityLogo.png")} alt="" />
          <h3>Vivre</h3>
        </div>
        <div className="vivre-des">
          <p>
          Vivre is a cloud based service for service provider or content aggregator marketing team to manage TV real estate. Vivre solution proposes different modules related to TV application services
          </p>
        </div>
        <OverlayCard blogs={blogs} />
      </div>
      <div className="cus-carousel">
        {/* <img src={require("../../assets/vivre/SARA Insights.png")} /> */}
        <Carousel data={whitepapers} />
        <div className="sara-cus-des">
          <div className="sara-cus-title">
            <h3>Multiple Projects,</h3>
            <h3> One Account</h3>
            <p>• All your projects can be present together under one account, which allows for quick changes across multiple platforms.
            </p>
            <p>• Get information about which screens in a project are active, along with the option to quickly modify them.</p>
          </div>

        </div>
      </div>
      <div>
        <div className="w-m-s-p">
          <div className="sara-title2">
            <h3>What makes our Vivre Services Special</h3>
          </div>
          <div className="features">
            <img src={require("../../assets/vivre/Frame1.png")} alt="" />
            <img src={require("../../assets/vivre/Frame2.png")} alt="" />
            <img src={require("../../assets/vivre/Frame3.png")} alt="" />
            <img src={require("../../assets/vivre/Frame4.png")} alt="" />
            <img src={require("../../assets/vivre/Frame5.png")} alt="" />
            <img src={require("../../assets/vivre/Frame6.png")} alt="" />

          </div>
        </div>
      </div>
      <NewFooter />
    </div>
  );
}
