import React from "react";
import NewFooter from "../../components/NewFooter/NewFooter";
import "./blinks.css";
import OverlayCard from "../../components/OverlayCard";
import vivre from "../../assets/solutions/Vivre.png";
import reel1 from "../../assets/Blinks/Frame 16.png";
import reel2 from "../../assets/Blinks/Frame 17.png";
import b1 from "../../assets/Blinks/blink1.png"
import b2 from "../../assets/Blinks/blink2.png"
import b3 from "../../assets/Blinks/blink3.png"
import { Carousel } from "../../components/VerticalCarousel";

const Blink = () => {
    const whitepapers = [
        {
            id: 1,
            src: reel1,
            alt: "image1 for carousel",
        },
        {
            id: 2,
            src: reel2,
            alt: "image 2 for carousel",
        },
    ];
    const blogs = [
        { title: 'Create', description: 'Build a following by creating your own unique content through Blinks. Your content will be shown to people with similar interests to ensure you get maximum audience reach.', name: '', image: b3 },
        { title: 'Share', description: 'Spread the creativity by sharing other users’ content both in and out of the app. Videos Embedded on other sites still add views and interactions to the original video.', name: '', image: b1 },
        { title: 'Discover', description: 'Our discovery algorithm  keeps you up-to-date with the latest trends and topics. Find interesting videos to share with your friends or get more ideas for your own content', name: '', image: b2 },
      
      ];
    return (
        <div>
            <div className="pt-[88px]">
                <div className="bcontainer">
                    <div className="blink-logo">
                        <img src={require("../../assets/nblink/Saralogo.png")} alt="" />
                        <h3>BLINKS</h3>
                    </div>
                    <div className="blink-des">
                        <p>
                            Blinks is a user generated content cloud platform, where users can create and share viral short form content, from anywhere at anytime.
                        </p>
                    </div>

                </div>
                {/* <img src={Poster} /> */}
                <div className="blinks-insights">
                    <OverlayCard  blogs={blogs}/>
                </div>
                <div className="blinks-carousel">
                    <Carousel data={whitepapers} />
                    <div className="blinks-des">
                        <div className="blinks-title">
                            <h3>Blink Insights</h3>
                            <p>Horizon harnesses innovative technology to deeply understand and enhance customer interactions,</p>
                            <p>refining their processes and strategies meticulously to foster continuous growth and success in the online realm.</p>
                        </div>

                    </div>
                </div>
                <div className="w-m-s-p">
                    <div className="sara-title2">
                        <h3>What makes BLINKS Special</h3>
                    </div>
                    <div className="features">
                        <img src={require("../../assets/nblink/Frame1.png")} alt="" />
                        <img src={require("../../assets/nblink/Frame2.png")} alt="" />
                        <img src={require("../../assets/nblink/Frame3.png")} alt="" />
                        <img src={require("../../assets/nblink/Frame4.png")} alt="" />
                        <img src={require("../../assets/nblink/Frame5.png")} alt="" />
                        <img src={require("../../assets/nblink/Frame6.png")} alt="" />

                    </div>
                </div>
                {/* <div> */}
                {/* <img src={Poster} /> */}
                {/* <img src={Des} /> */}
                {/* </div> */}
                {/* <div style={{ position: "relative" }}>
                    <img src={Features} />
                    <div
                        style={{
                            position: "absolute",
                            top: "35%",
                            right: "31%",
                            width: "400px",
                            height: "200px",
                        }}
                    >
                        <img src={DisTv} />
                    </div>
                </div> */}
            </div>
            <div>
                <NewFooter />

            </div>

        </div>
    );
};

export default Blink;
