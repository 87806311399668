import React, { useState } from "react";
import "./solution.css";
import vivre from "../../assets/solutions/Vivre.png";
import sia from "../../assets/solutions/sia.png";
import hospitality from "../../assets/solutions/hospitality.png";
import NewFooter from "../../components/NewFooter/NewFooter";

const images = {
  vivre: vivre, // Replace with your image paths
  sara: sia,
  hospitality: hospitality,
};
const title = {
  vivre:
    "Vivre is a cloud based service for service provider or content aggregator marketing team to manage TV real estate. Vivre solution proposes different modules related to TV application services.",
  sara: "SIA is an Authoring tool that allows operator to handle subscription plan effectively. Horizon offers platform solution integrated with Subscription Authoring tool which is a boon to operator to manage subscription plan on the fly without application change.",
  hospitality:
    "Hospitality solution offered by Horizon opens up opportunity to Monetize, customize localised content & extend Hotel specific value added service to Guest.",
};
const Solution = () => {
  const [selectedTab, setSelectedTab] = useState("vivre");

  const handleChange = (tab) => {
    setSelectedTab(tab);
  };
  return (
    <>
      <div className="solutions-container">
        <div className="section-three">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "0 10px",
              textAlign: "center",
            }}
          >
            <div className="wrapper-title">Template Platform Xpress</div>
            <div
              className="wrapper-description"
              style={{
                maxWidth: "80%",
                margin: "0 auto",
              }}
            >
              Unlock the future of streaming with our templated OTT platform,
              designed to deliver a seamless, scalable, and customizable user
              experience. With pre-built, ready-to-launch templates, you can
              fast-track your OTT service, significantly reducing development
              time and costs. Our solution offers full flexibility to match your
              brand's look and feel, while incorporating cutting-edge features
              like AI-driven content recommendations, multi-device
              compatibility, and real-time analytics. Whether you're launching a
              new service or enhancing an existing one, our platform ensures
              smooth, reliable performance and an engaging user experience
              across all devices.
            </div>
          </div>

          <div className="wrapper-group-visuals">
            <img
              src={require("../../assets/solutions/hos.png")}
              style={{ maxHeight: "550px" }}
            />
          </div>
          <div className="solution-container">
            <div className="solution-title">
              <div className="wrapper-title">
                Custom OTT Application
                {/* <span style={{ color: "#6054E4" }}>Your Business</span> */}
              </div>
            </div>
            <div className="solution-description">
              With ever growing OTT ecosystem, need for controlling
              intermediation with consumers is strategic. We offer tailor made
              solution and tools to enable our Customers. We are passionate
              about elevating the client and viewer experience. We’re genuinely
              excited about what we can offer. Please visit Product section and
              have a look at Customer reference insights.
            </div>
          </div>

          <div className="business-box">
            <div className="business-container">
              <div className="circle">
                <img
                  src={require("../../assets/solutions/Phone.png")}
                  alt="Phone"
                  className="phone-image"
                />
              </div>
              <div className="card top-left">
                <span className="card-title">SUBSCRIPTION (SVOD)</span>
                <p>
                  Where audiences pay a recurring subscription fee to access
                  your content. Create Daily, Monthly, Quarterly, Yearly
                  packages and more, and choose number of devices, Ad displays
                  and more!
                </p>
              </div>
              <div className="card middle-left">
                <span className="card-title">DISPLAY ADS (ADMOB)</span>
                <p>
                  Integrate AdMob and other display Ad exchanges to generate
                  revenue from non-paying customers. Choose only Ad-based or a
                  Hybrid monetisation strategy. Its your choice!
                </p>
              </div>
              <div className="card bottom-left">
                <span className="card-title">PAY-PER VIEW/RENT (TVOD) </span>
                <p>
                  Integrate AdMob and other display Ad exchanges to generate
                  revenue from non-paying customers. Choose only Ad-based or a
                  Hybrid monetisation strategy. Its your choice!
                </p>
              </div>
              <div className="card top-right">
                <span className="card-title">
                  VAST BASE IN-VIDEO ADS (AVOD)
                </span>
                <p>
                  Enable video content to be watched for free and generate
                  revenue through in-video ads through Google Ad Manager.
                  Generate revenue by presenting audiences with paid
                  advertisements or purchasing options for an Ad-Free Experience
                </p>
              </div>
              <div className="card middle-right">
                <span className="card-title">CUSTOM DISPLAY ADS</span>
                <p>
                  Custom Banner Ads placed in high-traffic locations on web
                  pages can help you get sponsors/promoters and generate offline
                  revenue. These high-visibility locations include the front,
                  bottom or the side of a webpages
                </p>
              </div>
              <div className="card bottom-right">
                <span className="card-title">TIPS/DONATIONS</span>
                <p>
                  Collect donations and tips from your loyal audience for your
                  content, for both Live Streams and VOD Content. Generate cash
                  revenue apart from subscriptions and rent through this!
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="section-four">
          <div className="section-four-content">
            <h3 className="section-four-title">OTT Content Aggregation</h3>
            <p className="section-four-description">
              Global OTT market is expanding exponentially as users enjoy
              content from different content providers. Users look for a
              platform that aggregates and offers content in one click. At
              Horizon Broadband, we integrate and offer traditional OTT services
              along with aggregated content platform solutions. Growth of OTT is
              driven by viewer consumption across the world, with an increase in
              demand for various applications such as Netflix, Prime, Disney+,
              Disney Hotstar, HBO, Lions Gate etc.
            </p>
          </div>
        </div>
        <div className="section-two">
          <img src={require("../../assets/solutions/Wtools.png")} />
        </div>
        <div className="section-one">
          <div className="section-one-title">
            <h3>Editorial Curation Tools</h3>
          </div>
          <div className="discription">
            <span>
              With ever growing OTT ecosystem, need for controlling
              intermediation with consumers is strategic. We offer tailor made
              solution and tools to enable our Customers.
            </span>
          </div>
          <div className="section-one-tab-wrapper">
            <div className="section-one-tab-container">
              <div className="tabs">
                <button
                  className={`tab-button ${
                    selectedTab === "vivre" ? "active" : ""
                  }`}
                  onClick={() => handleChange("vivre")}
                >
                  Vivre
                </button>
                <button
                  className={`tab-button ${
                    selectedTab === "sara" ? "active" : ""
                  }`}
                  isActive={selectedTab === "sara"}
                  onClick={() => handleChange("sara")}
                >
                  S.I.A
                </button>
                <button
                  className={`tab-button ${
                    selectedTab === "hospitality" ? "active" : ""
                  }`}
                  isActive={selectedTab === "hospitality"}
                  onClick={() => handleChange("hospitality")}
                >
                  Hospitality
                </button>
              </div>
              <div className="tab-item">
                <img
                  src={images[selectedTab]}
                  alt={selectedTab}
                  style={{ height: "100%", width: "100%" }}
                />
              </div>
              <div className="tab-item-description">
                <p className="tab-item-description">{title[selectedTab]}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <NewFooter />
    </>
  );
};

export default Solution;
