import React from "react";
import "./sara.css";
import NewFooter from "../../components/NewFooter/NewFooter";
import { Carousel } from "../../components/VerticalCarousel";
import img6 from "../../assets/nsara/image6.png";
import img7 from "../../assets/nsara/image7.png";
import img8 from "../../assets/nsara/Sara Screenshot 5.png";
import b1 from "../../assets/nsara/b1.png";
import b2 from "../../assets/nsara/b2.png";

export default function Sara() {
  const whitepapers = [
    {
      id: 1,
      src: img6,
      alt: "image1 for carousel",
    },
    {
      id: 2,
      src: img7,
      alt: "image 2 for carousel",
    },
    {
      id: 3,
      src: img8,
      alt: "image 3 for carousel",
    },
  ];

  const blogs = [
    { id: '1', title: "Recommendations Driven by AI", description: "Personalizing User Experience is a key driver of OTT app success. Horizon’s Recommendation Engine SARA uses AI and data analytics to understand user preferences and behaviour, allowing for tailored content recommendations.", name: '', image: b1 },
    { id: '2', title: "Monetization", description: "By analysing user data, OTT Platforms can deliver targeted ads that are more likely to be relevant and engaging. This benefits both users and advertisers, as it increases the effectiveness of advertising while reducing the annoyance of irrelevant ads.", name: '', image: b2 },
  ];

  const BlogCard = ({ title, description, name, image }) => (
    <div className="sara-styled-card">
      <img className="overlay-image" src={image} alt={title} />
      <div className="card-content">
        <h5 className="title">{title}</h5>
        <p className="description">{description}</p>
        {/* <p className="author">Author: {name}</p> */}
      </div>
    </div>
  );

  return (
    <>
      <div className="sara-container">
        <div className="sara-first">
          <div className="logo">
            <img src={require("../../assets/aboutus/Sara.png")} alt="Sara Logo" />
          </div>
          <div className="sara-title">
            <h3>S.A.R.A</h3>
          </div>
          <div className="sara-des">
            <p>
            Personalizing User Experience is a key driver of OTT app success.
            </p>
            <p>Horizon’s Recommendation Engine SARA uses AI and data analytics to understand user preferences and behaviour, allowing for tailored content recommendations.</p>
          </div>
          <div className="img-section">
            {blogs.map((blog, index) => (
              <BlogCard key={index} {...blog} />
            ))}
          </div>
        </div>
        <div className="insight">
          <div className="sara-carousel">
            <Carousel data={whitepapers} />
            <div className="cus-des">
              <div className="cus-title">
                <h3>S.A.R.A Insights</h3>
                <p>Horizon harnesses innovative technology to deeply understand and enhance customer interactions,</p>
                <p>refining their processes and strategies meticulously to foster continuous growth and success in the online realm.</p>
              </div>
            </div>
          </div>
        </div>

        <div className="w-m-s-p">
          <div className="sara-title2">
            <h3>What makes S.A.R.A Special</h3>
          </div>
          <div className="features">
            <img src={require("../../assets/nsara/Frame1.png")} alt="" />
            <img src={require("../../assets/nsara/Frame2.png")} alt="" />
            <img src={require("../../assets/nsara/Frame3.png")} alt="" />
            <img src={require("../../assets/nsara/Frame4.png")} alt="" />
            <img src={require("../../assets/nsara/Frame5.png")} alt="" />
            <img src={require("../../assets/nsara/Frame6.png")} alt="" />
            <img src={require("../../assets/nsara/Frame7.png")} alt="" />
            <img src={require("../../assets/nsara/Frame8.png")} alt="" />
            <img src={require("../../assets/nsara/Frame9.png")} alt="" />
          </div>
        </div>
        <NewFooter />
      </div>
    </>
  );
}
