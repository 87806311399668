import React, { useEffect } from 'react'
import OTT_Products from './OTTProducts'

let navigator, dnaOffset, wwdOffset, ottProductsOffset, swuOffset, clientsOffset, contactOffset, careersOffset

let TopNavigator, wwdNavigator, ottProductNavigator, swuNavigator, clientsNavigator, contactNavigator, careersNavigator

const MenuList = [
    {id:"Top", content:"Top", link: "#top"},
    {id:"What-We-Do", content:"What We Do", link: "#wwd"},
    {id:"OTT-Products", content:"OTT Products", link: "#ott-products"},
    {id:"Start-With-Us", content:"Start With Us", link: "#swu"},
    {id:"Clients", content:"Clients", link: "#clients"},
    {id:"Contact", content:"Contact", link: "#contact"},
    {id:"Careers", content:"Careers", link: "#careers"}
]

const childShow = (Elements) =>{
    const ChildElements = Elements.querySelectorAll("span")
    Elements.classList.add("active")
    Elements.classList.add("bg-sky-600")
    Elements.classList.add("border-2")

    ChildElements[0].classList.remove("invisible")
    ChildElements[1].classList.remove("border-black")

}

const childHide = (Elements) =>{
    const ChildElements = Elements.querySelectorAll("span")
    Elements.classList.remove("active")
    Elements.classList.remove("bg-sky-600")
    Elements.classList.remove("border-2")

    ChildElements[0].classList.add("invisible")
    ChildElements[1].classList.add("border-black")
}


const navigaterController = ()=>{


    const ScrollY = window.scrollY+2

    
    if(ScrollY <= dnaOffset){
        navigator.classList.remove("-translate-x-40")
    }
    else if(ScrollY >= dnaOffset){
        navigator.classList.add("-translate-x-40")
    }

    if(ScrollY >= dnaOffset && ScrollY <= wwdOffset){
        TopNavigator.classList.add("bg-sky-600")
        childShow(TopNavigator)

        if(wwdNavigator.classList.contains("active")){
            childHide(wwdNavigator)
        }
    }

    if(ScrollY >= wwdOffset && ScrollY <= ottProductsOffset){

        childHide(TopNavigator)
        childShow(wwdNavigator)

        if(ottProductNavigator.classList.contains("active")){
            childHide(ottProductNavigator)
        }

    }

    if(ScrollY >= ottProductsOffset && ScrollY <= swuOffset){

        childHide(wwdNavigator)
        childShow(ottProductNavigator)

        if(swuNavigator.classList.contains("active")){
            childHide(swuNavigator)
        }

    }

    if(ScrollY >= swuOffset && ScrollY <= clientsOffset){

        childHide(ottProductNavigator)
        childShow(swuNavigator)

        if(clientsNavigator.classList.contains("active")){
            childHide(clientsNavigator)
        }

    }

    if(ScrollY >= clientsOffset && ScrollY <= contactOffset){

        childHide(swuNavigator)
        childShow(clientsNavigator)

        if(contactNavigator.classList.contains("active")){
            childHide(contactNavigator)
        }

    }

    if(ScrollY >= contactOffset && ScrollY <= careersOffset){

        childHide(clientsNavigator)
        childShow(contactNavigator)

        if(careersNavigator.classList.contains("active")){
            childHide(careersNavigator)
        }

    }

    if(ScrollY+100 >= careersOffset){

        childHide(contactNavigator)
        childShow(careersNavigator)

    }

}

window.addEventListener('scroll', navigaterController);


    


export default function  Navigator() {

    useEffect(()=>{
        

            navigator = document.getElementById("navigator")
            dnaOffset = document.getElementById('dna').offsetTop;
            wwdOffset = document.getElementById('wwd').offsetTop;
            ottProductsOffset = document.getElementById('ott-products').offsetTop;
            swuOffset = document.getElementById('swu').offsetTop;
            clientsOffset = document.getElementById('clients').offsetTop;
            contactOffset = document.getElementById('contact').offsetTop;
            careersOffset = document.getElementById('careers').offsetTop;

            TopNavigator = document.getElementById('Top')
            wwdNavigator = document.getElementById('What-We-Do')
            ottProductNavigator = document.getElementById('OTT-Products')
            swuNavigator = document.getElementById('Start-With-Us')
            clientsNavigator = document.getElementById('Clients')
            contactNavigator = document.getElementById('Contact')
            careersNavigator = document.getElementById('Careers')
            
            let x = window.location.hash
            if(x === "#dna"){
                window.scrollTo(0,dnaOffset)
            }
            else if(x === "#wwd"){
                window.scrollTo(0,wwdOffset)
            }
            else if(x === "#ott-products"){
                window.scrollTo(0,OTT_Products)
            }
            else if(x === "#swu"){
                window.scrollTo(0,swuOffset)
            }
            else if(x === "#Clients"){
                window.scrollTo(0,clientsOffset)
            }
            else if(x === "#Contact"){
                window.scrollTo(0,contactOffset)
            }
            else if(x === "#Careers"){
                window.scrollTo(0,careersOffset)
            }
            else{
                window.scrollTo(0,0)
            }

        
    },[])
    
  return (
    <div  className='invisible md:visible translate-x-80 z-10 top-0 right-0 fixed w-[20rem] h-full flex items-center'>
        <div id='navigator' className='transition duration-300 '>
            {
                MenuList.map((menu, i)=>{
                    return <a href={menu.link} id={menu.id} key={i} className='group py-1 px-4 mb-3 hover:cursor-pointer text-white hover:bg-sky-600 flex justify-end rounded-full'>
                        <span className='group-hover:visible mr-3 invisible'>{menu.content}</span>
                        <span className='bg-white group-hover:border-0 mt-2.5 h-2 w-2 border border-black rounded'></span>
                    </a>
                })
            }
        </div>
    </div>
  )
}
