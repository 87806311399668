import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import viv from "../../assets/banner/VivreBanner.png";
import sara from "../../assets/banner/SaraBanner.png";
import blink from "../../assets/banner/BlinksBanner.png";
import hospitality from "../../assets/banner/Hospitality Banner.png";
import NewFooter from "../../components/NewFooter/NewFooter";

// Styled Components
const GradientText = styled.h2`
  font-size: 1.8rem;
  margin: 10px 0;
  background: ${(props) =>
    `linear-gradient(45deg, ${props.gradientStart}, ${props.gradientEnd})`};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  animation: colorChange 5s infinite;
  @keyframes colorChange {
    0% {
      background-position: 0% 0%;
    }
    50% {
      background-position: 100% 100%;
    }
    100% {
      background-position: 0% 0%;
    }
  }
`;

const BoldText = styled.span`
  font-weight: bold;
  color: #333;
`;

const Section = styled.div`
  margin-bottom: 10px;
  font-size: 1rem;
  color: #666;
`;

const CenterAlignedSection = styled(Section)`
  text-align: center;
`;

const ProductList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  max-width: 1200px;
  margin: 80px auto 60px;
`;

const ProductItem = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  &:hover {
    transform: scale(1.02);
  }
`;

const BannerImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
`;

const ProductContent = styled.div`
  padding: 20px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center align text */
  position: relative;
`;

const ProductTitle = styled.h2`
  font-size: 1.8rem;
  margin: 10px 0;
  color: #333;
`;

const ProductDescription = styled.div`
  font-size: 1rem;
  color: #666;
  margin-bottom: 20px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

const ViewMoreButton = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  &:hover {
    background-color: #0056b3;
  }
`;

const ArrowIcon = styled.svg`
  width: 20px;
  height: 20px;
  margin-left: 8px;
  fill: white;
`;
const BulletPoints = styled.ul`
  list-style-type: disc;
  padding-left: 20px;
  margin-bottom: 15px;
  margin-left: 0;
  margin-top: 20px;
`;

const ListItem = styled.li`
  margin-bottom: 10px;
  list-style-position: inside;
  padding-left: 5px;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    left: 0;
    color: black;
  }
`;
const products = [
  {
    // name: "Vivre",
    logo: "https://source.unsplash.com/random/80x80?logo",
    description: (
      <>
        <CenterAlignedSection>
          <BoldText>
            Vivre is an Experience Manager that allows for UI/UX customization
            of applications to give a unified experience across platforms in
            real-time, which can be connected to any Content Management System.
          </BoldText>
        </CenterAlignedSection>
        <Section>
          <BoldText>Faster Launch:</BoldText> Accelerate time-to-market by
          shaving months off development, enabling you to launch your product
          quickly and stay ahead of competitors.
        </Section>
        <Section>
          <BoldText>Tailor the Screen on the Fly:</BoldText> Prioritize your
          content with targeted user experiences (UX) and customized layouts
          that perfectly align with your brand's needs in real time.
        </Section>
        <Section>
          <BoldText>Launch Across 12+ Platforms Simultaneously:</BoldText>{" "}
          Streamline the deployment process across multiple platforms,
          minimizing overheads and reducing complexity.
        </Section>
        <Section>
          <BoldText>Craft a Unique Brand Experience:</BoldText> Deliver a
          personalized, standout user experience that reflects the core of your
          brand, differentiating it from others in the market.
        </Section>
        <Section>
          <BoldText>Rich, Visually Appealing Templates:</BoldText> Choose from a
          growing library of high-quality templates designed to captivate your
          audience with eye-catching visuals.
        </Section>
        <Section>
          <BoldText>
            Drive Engagement Through Easy Discovery & Binge-Watching:
          </BoldText>{" "}
          Boost user retention and engagement with intuitive browsing and
          binge-watching features.
        </Section>
        <Section>
          <BoldText>Market Standard Third-Party SDKs Pre-integrated:</BoldText>{" "}
          Leverage development with pre-integrated third-party SDKs or plug-ins.
        </Section>
      </>
    ),
    banner: viv,
    link: "/product/vivre",
    gradientStart: "#FF7E5F",
    gradientEnd: "#FF6F61",
  },
  {
    // name: "Hospitality",
    logo: "https://source.unsplash.com/random/80x80?hotel",
    description: (
      <>
        <CenterAlignedSection>
          <BoldText>Future of OTT Entertainment in Hospitality</BoldText>
        </CenterAlignedSection>
        <Section>
          At Horizon Broadband, we understand competitive demand for Hospitality
          media entertainment solution and offer turn key OTT/IPTV Hospitality
          suite. Horizon Hospitality Suite gives Operator flexibility and an
          edge in the hotel industry. Hotels are striving to provide value added
          service & monetise service offering, guests’ expectations for
          personalized engagement is ever-increasing. Horizon
        </Section>
        <Section>
          <BoldText>Live & on-demand</BoldText>Operator/Hotels has control over services that can be customized and offered for Hotel chain. Variety of content for families, international guests, sports fans & business travelers
        </Section>
        <Section>
          <BoldText>Easy to use</BoldText>Simple user interface and Management console for Operators and Hotels. 
        </Section>
        <Section>
          <BoldText>Secure</BoldText>Media DRM and Device CRM integration makes it a secure offering. Express checkout with Mobile web interface.
        </Section>
        <Section>
          <BoldText>Compatible</BoldText>Horizon Hospitality Suite uses familiar technology that works with ATV, STB, Dongle.
        </Section>
        <Section>
          <BoldText>Live & on-demand</BoldText>Operator/Hotels has control over services that can be customized and offered for Hotel chain. Variety of content for families, international guests, sports fans & business travelers
        </Section>
      </>
    ),
    banner: hospitality,
    link: "/product/hospitality",
    gradientStart: "#6EE7B7",
    gradientEnd: "#3A80D0",
  },
  {
    // name: "Sara",
    logo: "https://source.unsplash.com/random/80x80?service",
    description: (
      <>
        <CenterAlignedSection>
          <BoldText>
            OTT strategy in tune to customer need and consumption
          </BoldText>
        </CenterAlignedSection>
        <Section>
          OTT media entertainment is a connected multi-platform with viewer
          appetite to consume content with one touch and view of interested
          content whenever and wherever they want across multiple devices. It’s
          imperative to understand viewer personal preferences at a granular
          level.
        </Section>
        <Section>
          SARA, AI powered Recommendation Engine integrates with large and
          complex content catalogue data sets and helps unlock the hidden
          opportunities in viewer engagement and retention, surfacing
          insightful, timely inference through advanced analytics and
          dashboards.
        </Section>
        <Section>
          <BoldText>
            {" "}
            Here are some key Benefits of SARA Recommendation Engine.:
          </BoldText>

          <BulletPoints>
            <ListItem>Monitoring on-screen performance</ListItem>
            <ListItem>Personalised Push Notification</ListItem>
            <ListItem>Driving content upsell</ListItem>
            <ListItem>Improving customer experience and satisfaction</ListItem>
            <ListItem>Advanced Search Statistics</ListItem>
            <ListItem>Automated Trend Analysis & Audience Behaviors</ListItem>
            <ListItem>Recommendation types</ListItem>
          </BulletPoints>
        </Section>
      </>
    ),
    banner: sara,
    link: "/product/sara",
    gradientStart: "#FF9A8B",
    gradientEnd: "#FF6F61",
  },
  {
    // name: "Blinks",
    logo: "https://source.unsplash.com/random/80x80?entertainment",
    description: (
      <>
        <CenterAlignedSection>
          <BoldText>Blinks – Create, Share, Discover Short Videos</BoldText>
        </CenterAlignedSection>
        <Section>
          <BoldText>Video Sharing & Content Creation Service:</BoldText> Enable
          users to create and share engaging short-form videos on a platform
          optimized for dynamic content.
        </Section>
        <Section>
          <BoldText>Create Viral Short-Form Content:</BoldText> Facilitate the
          creation of highly shareable videos designed to go viral and capture
          audience attention.
        </Section>
        <Section>
          <BoldText>Increase Advertisement Revenues:</BoldText> Maximize ad
          revenue opportunities through high engagement and targeted ad
          placements.
        </Section>
        <Section>
          <BoldText>Attract Influencers & Content Creators:</BoldText> Build a
          community of top influencers and content creators, enhancing platform
          visibility and growth.
        </Section>
        <Section>{""}</Section>
        <Section>
          <BoldText>
            {" "}
            Here are some key Benefits of SARA Recommendation Engine.:
          </BoldText>
          <BulletPoints>
            <Section>
              <BoldText>Platforms – Android & iOS:</BoldText> Seamless access
              across both Android and iOS, ensuring a broad audience reach.
            </Section>
            <Section>
              <BoldText>Ultra Low Latency Playback:</BoldText> Provide
              near-instant playback, offering an optimal viewing experience with
              minimal delay.
            </Section>
            <Section>
              <BoldText>Content Moderation :</BoldText>Ensure a safe and
              welcoming environment with effective moderation tools to manage
              user-generated content.
            </Section>
            <Section>
              <BoldText>Gamification :</BoldText> Engage users through
              interactive features like gamification, driving participation and
              retention.
            </Section>
            <Section>
              <BoldText>Contests & Leaderboards: :</BoldText>Encourage
              competition and boost engagement by offering contests and
              leaderboards to reward top creators.
            </Section>
            <Section>
              <BoldText>Ads:</BoldText> Monetize with integrated ad support,
              optimizing revenue generation without disrupting user experience.
            </Section>
            <Section>
              <BoldText>Video Filters & Audio Effects:</BoldText> Enhance
              creative expression with a wide range of video filters and audio
              effects for polished, engaging content.
            </Section>
            <Section>
              <BoldText>Social Share, Follow & More :</BoldText> Build a social
              ecosystem where users can follow others, share content, and grow
              their networks.
            </Section>
            <Section>
              <BoldText>LIVE Broadcast of Short Videos:</BoldText> Empower users
              to stream short-form content live, increasing real-time engagement
              and interaction.
            </Section>
          </BulletPoints>
        </Section>
      </>
    ),
    banner: blink,
    link: "/product/blink",
    gradientStart: "#FDC830",
    gradientEnd: "#F37335",
  },
];

const AllProducts = () => {
  const navigate = useNavigate();

  const handleViewMore = (link) => {
    navigate(link);
  };

  return (
    <>
      <ProductList>
        {products.map((product, index) => (
          <ProductItem key={index}>
            <BannerImage src={product.banner} alt={`${product.name} banner`} />
            <ProductContent>
              <GradientText
                gradientStart={product.gradientStart}
                gradientEnd={product.gradientEnd}
              >
                {product.name}
              </GradientText>
              <ProductDescription>{product.description}</ProductDescription>
              <ButtonWrapper>
                <ViewMoreButton onClick={() => handleViewMore(product.link)}>
                  View More
                  <ArrowIcon
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                  >
                    <path d="M10.59 16.59L15.17 12 10.59 7.41 12 6l6 6-6 6-1.41-1.41z" />
                  </ArrowIcon>
                </ViewMoreButton>
              </ButtonWrapper>
            </ProductContent>
          </ProductItem>
        ))}
      </ProductList>
      <NewFooter />
    </>
  );
};

export default AllProducts;
