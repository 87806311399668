import React, { useState, useEffect } from "react";
import "./Carousel.css";
import leftArrow from "../../../assets/Arrow1000.png"
import rightAroow from "../../../assets/Arrow2000.png"
import { useNavigate } from "react-router-dom";

export const Carousel = ({ data, onItemSelect, page }) => {
  const [slide, setSlide] = useState(0);
  const navigate = useNavigate();

  const nextSlide = () => {
    setSlide((prevSlide) => (prevSlide === data.length - 1 ? 0 : prevSlide + 1));
    if (onItemSelect) {
      onItemSelect(slide, "nextslide");

    }
  };

  const prevSlide = () => {
    setSlide((prevSlide) => (prevSlide === 0 ? data.length - 1 : prevSlide - 1));
    if (onItemSelect) {
      onItemSelect(slide, "nextslide");

    }

  };

  useEffect(() => {
    console.log("effect")
    if (onItemSelect) {
      onItemSelect(slide);

    }
  }, []);


  return (
    <div className="carousele z-10">

      <div className="gradient-overlay "></div>

      <img src={leftArrow}
        alt="" onClick={prevSlide}
        className={`arrow z-10 arrow-left  z-20`} />


      {data.map((item, idx) => {
        return (
          <div onClick={() => page === "resource" && onItemSelect(item.id)}>
            <img
              src={item.src}
              alt={item.alt}
              key={idx}
              className={slide === idx ? "slide active z-10  object-cover" : "slide slide-hidden w-full h-full  object-cover"}
            // onClick={() => navigate(item.href)}
            />
          </div>
        );
      })}


      <img src={rightAroow} alt="" onClick={nextSlide}
        className={`arrow z-20 arrow-right `} />

      <span className="indicators z-20">
        {data.map((_, idx) => {
          return (
            <button
              key={idx}
              className={
                slide === idx ? "indicator active" : "indicator indicator-inactive"
              }
              onClick={() => setSlide(idx)}
            ></button>
          );
        })}
      </span>

    </div >
  );
};