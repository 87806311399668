import React from 'react';
import { CardBox, ContainerCard } from './Card.style';
import { Typography } from '@mui/material';
// import sara from "../../assets/partners/products/sara.png";
// import vivre from "../../assets/partners/products/insight1-modified.png";
// import hospitality from "../../assets/partners/products/Hospitality.png";
// import blink from "../../assets/partners/products/cropped-Blinks.png";
// ----------

import sara from "../../assets/products/SARATab.png";
import vivre from "../../assets/products/Vivretab.png";
import blink from "../../assets/products/Blinkstab.png";
import hospitality from "../../assets/products/Hospitality.png";
import { useNavigate } from 'react-router';




const Card = () => {
    const navigate = useNavigate();
    const gallery = [
        {
            id:"viv",
            name: "", img: vivre, des: ['Operator friendly SAAS User Experience Platform',
                'CMS Integrated - Flexible for Curation Team',
                'Realtime Platform Frontend Updates',
                'Immersive UI/UX experience across Platform',
                'Standard API interface for Platform Integration',
                'Application Analytics'
            ], title: 'VIVRE USER EXPERIENCE'
        },

        {
            id:"sara",
            name: "", img: sara, des: ['SAAS AI Powered User Experience',
                'Content Discovery made easy through Recommendations',
                'Personalised Recommendation',
                'Monetization & Gamification',
                'Standard API interface for Platform Integration',
                'Content/User Consumption Analytics',
            ], title: 'SARA'
        },
        {
            id:"hospitality",
            name: "", img: hospitality, des: ['Enabler for OTT Hospitality Guest Experience',
                'Unified solution for Hotel OTT monetisation, value added service',
                ' Operator friendly administrative tools',
                'Standard API interface for PMS integration',
                'Pre-integrated with CMS'
            ],
            title: 'HOSPITALITY'
        },

        { id:"blink",name: "", img: blink, des: ['User Generated Content - Video creation & sharing platform', 'Monetize through Ads and Commerce', 'Gamification services through contests and leaderboards', 'SAAS Platform integrated with Mobile'], title: 'Blinks' },
    ];

    const handleNavigate=({ item, index })=>{
        console.log(item,index,"item,index")
        if (item.id==="viv") {
            navigate('/product/vivre')  
        } else if(item.id==="sara"){
            navigate('/products')  
        }else if (item.id==="hospitality") {
            navigate('/product/hospitality')  
        }else if(item.id==="blink"){
            navigate('/product/blink')  

        }
        
    }
    return (
        <ContainerCard >
            {gallery.map((item, index) => (
                <CardBox key={index} img={item.img} onClick={() => handleNavigate({ item, index })}>
                    <div className='front'>
                        {item.name}
                    </div>

                    <div className='back'>
                        <Typography style={{
                            fontSize: "1.1rem",
                            lineHeight: "1.75rem",
                            letterSpacing: ".0625rem",
                            fontWeight: "bold",
                            paddingTop: "0px",
                            fontFamily: "Open Sans,Helvetica,Arial,sans-serif"
                        }} variant='h5'>{item.title}</Typography>
                         <div className='description'>
                            <ul>
                                {item.des.map((v, i) => (
                                    <li key={i}>
                                        <Typography style={{ color: "black" }}>{v}</Typography>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>

                </CardBox>
            ))}
        </ContainerCard>
    );
};

export default Card;
