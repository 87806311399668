import React, { useState } from 'react';
import styled from 'styled-components';
import b1 from "../../assets/blogs/b1.png"
import b2 from "../../assets/blogs/b2.png"
import b3 from "../../assets/blogs/b3.png"
import b4 from "../../assets/blogs/b4.png"
import b5 from "../../assets/blogs/b5.png"


const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
`;

const Header = styled.header`
  text-align: center;
  margin: 20px 0;
`;


const CardGrid = styled.div`
    display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 columns */
  gap: 20px; /* Space between cards */
  @media (max-width: 1200px) {
    grid-template-columns: repeat(3, 1fr); /* 2 columns on medium screens */
  }
  @media (max-width: 800px) {
    grid-template-columns: 1fr; /* 1 column on small screens */
  }
`;

const StyledCard = styled.div`
//   width: 390px;
  width: 315px;
  height: 260px;
  position: relative;
  overflow: visible; /* Allow the image to overflow */
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top:8rem;
`;

const OverlayImage = styled.img`
  width: 450px;
  height: 280px;
  position: absolute;
  top: -140px; /* Half of the image height to center it above the card */
  left: 50%;
  transform: translateX(-50%);
  border-radius: 8px 8px 0 0;
`;

const CardContent = styled.div`
  padding: 20px;
  margin-top:6rem;
`;

const Title = styled.h5`
  margin: 0;
  font-size: 20px;
  color:#6054E4
`;

const Description = styled.p`
  color: #555;
  font-size:14px

`;

const Author = styled.p`
  color: #777;
`;

const ShowMoreButton = styled.button`
  display: block;
  margin: 20px auto;
  padding: 10px 20px;
  font-size: 1rem;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #0056b3;
  }
`;





export default function OverlayCard({blogs}) {
  const [visibleBlogs, setVisibleBlogs] = useState(3);
  // const blogs = [
  //   { title: 'Omni Channels', description: 'We have enabled many large operators with Omni channel experience for hotel guests.', name: '', image: imgObj?.b1 },
  //   { title: 'Experience', description: 'Bring unparallel user experience through unification of OTT, Gaming, E-Commerce and other service offerings.', name: '', image: imgObj?.b2 },
  //   { title: 'Revenue', description: 'Increase your offerings / revenues from your Guests by upto 2x.', name: '', image: imgObj?.b3 },
  
  // ];
  const BlogCard = ({ title, description, name, image }) => (
    <StyledCard>
      <OverlayImage src={image} alt={title} />
      <CardContent>
        <Title>{title}</Title>
        <Description>{description}</Description>
        <Author>{name}</Author>
      </CardContent>
    </StyledCard>
  );
  return (
    <Container>
      <CardGrid>
        {blogs.map((blog, index) => (
          <BlogCard key={index} {...blog} />
        ))}
      </CardGrid>
    </Container>
  );

}
