import React, { useState } from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  //   width: 800px;
  margin: 0 auto;
`;
const HalfCircleContainer = styled.div`
  position: relative;
  width: 100%;
  height: 375px;
  background: linear-gradient(180deg, #6054e4 0%, #5c218a 100%);
  border-radius: 400px 400px 0 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CarouselContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 800px; /* Fixed width to match image width */
  height: 550px; /* Fixed height to match image height */
  position: relative;
  overflow: hidden;
`;

const CarouselImage = styled.img`
  // width: 40%;
  height: 50%;
  object-fit: cover;
  border-radius: 0.5rem;
`;

const Button = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: linear-gradient(0deg, #3f96ef 0%, #885cf7 100%);
  border: none;
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  z-index: 1;
  font-size: 20px;
  transition: background 0.3s;

  &:hover {
    background: linear-gradient(0deg, #3f96ef 10%, #885cf7 90%);
  }
`;

const LeftButton = styled(Button)`
  left: 60px;
`;

const RightButton = styled(Button)`
  right: 60px;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 20px;
  background: rgba(
    255,
    255,
    255,
    0.8
  ); /* Slightly transparent background for readability */
  border-radius: 0 0 20px 20px; /* Rounded bottom corners for the text section */
`;
const Title = styled.h2`
  margin: 0;
  color: #6054e4;
  text-align: center;
  font-size: 30px;
  font-weight: bold;
   font-weight: 700;

`;

const Description = styled.p`
  margin: 10px 0 0 0;
  color: #555555 ;
  text-align: center;
  font-size: 16px;
`;
const SingleImageCarousel = ({ data }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const { image, title, description } = data[currentIndex];
  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % data.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + data.length) % data.length);
  };

  return (
    <Container>
      <HalfCircleContainer>
        <CarouselContainer>
          <LeftButton onClick={handlePrev}>{"<"}</LeftButton>
          <CarouselImage src={image} alt={`Image ${currentIndex + 1}`} />
          <RightButton onClick={handleNext}>{">"}</RightButton>
        </CarouselContainer>
      </HalfCircleContainer>
      <TextContainer>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </TextContainer>
    </Container>
  );
};

export default SingleImageCarousel;
